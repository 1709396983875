import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import {
    MIXMATCH_LEVEL, MIXMATCH_OPERATION, MIXMATCH_TYPE, MM_PRINT, STATUS
} from "../../shared/utils/Constants";
import {InputSwitch} from "primereact/inputswitch";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import ProductGroupProductColumn from "../../shared/component/dataTable/columns/ProductGroupProductColumn.json";
import ProductGroupService from "../../shared/service/ProductGroupService";
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import {InputNumber} from "primereact/inputnumber";
import {AutoComplete} from "primereact/autocomplete";
import {Checkbox} from "primereact/checkbox";
import DateInput from "../../shared/component/form/DateInput";
import MixAndMatchService from "../../shared/service/MixAndMatchService";
import ProductService from "../../shared/service/ProductService";


const defaultMixAndMatch = {
    id: '',
    name: '',
    status: 'ACTIVE',
    mixAndMatchType: 'MIXMATCH',
    level: 'PRODUCT',
    operation: 'FIXED_PRICE',
    quantity: null,
    amount: null,
    startDate: null,
    endDate: null,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    products: [],
    startHour: null,
    startMinute: null,
    duration: null,
    mmPrint: 'LABEL',
    collection: false,
    delivery: false,
    till: false
};

export default class MixAndMatchSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mixAndMatch: Object.assign({}, defaultMixAndMatch),
            mixAndMatchTemp: Object.assign({}, defaultMixAndMatch),
            mixAndMatchSaveButtonRender: true,
            defaultStatus: 'ACTIVE',
            barcode: '',
            name: '',
            filteredProducts: []
        }
        this.productGroupService = new ProductGroupService();
        this.productService = new ProductService();
        this.mixAndMatchService = new MixAndMatchService();
    }

    componentDidMount() {
        let id = window.location.href.split('?')[1];
        if (!GeneralUtils.__isValueEmpty(id)) {
            this.mixAndMatchService.findById(id).then(response => {
                this.setState({
                    mixAndMatch: Object.assign({}, response),
                    mixAndMatchSaveButtonRender: false,
                    barcode: '',
                    selectedProduct: '',
                    mixAndMatchTemp: Object.assign({}, response)
                });
            });
        } else {
            let obj1 = Object.assign({}, defaultMixAndMatch);
            this.setState({
                mixAndMatch: Object.assign({}, obj1),
                mixAndMatchSaveButtonRender: true,
                barcode: '',
                selectedProduct: '',
                mixAndMatchTemp: Object.assign({}, obj1)
            });
        }

    }


    render() {
        return (<div>
            <AvForm>
                {this.mixAndMatches()}
            </AvForm>
        </div>);
    }

    returnListPage = () => {
        window.location.href = "#mixAndMatchList";
    }

    setCheckedStatus = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.id)) {
            this.mixAndMatchService.updateStatus(this.state.mixAndMatch.id, this.state.defaultStatus)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            mixAndMatch: Object.assign({}, response),
                            mixAndMatchSaveButtonRender: false,
                            mixAndMatchTemp: Object.assign({}, response)
                        });
                    }
                });
        } else {
            let mixAndMatch = this.state.mixAndMatch;
            mixAndMatch["status"] = this.state.mixAndMatch.status == "ACTIVE" ? "PASSIVE" : "ACTIVE";
            this.setState({mixAndMatch: Object.assign({}, mixAndMatch), mixAndMatchTemp: Object.assign({}, mixAndMatch)});
        }

        return undefined;
    }

    showChangeStatusConfirmDialog = (value) => {
        let defaultStatus = 'PASSIVE';
        if (value) {
            defaultStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            defaultStatus: defaultStatus,
            noticeResponse: "You are about to change the status. Are you sure?"
        });
    }
    setChecked = (name, value) => {
        let mixAndMatch = this.state.mixAndMatch;
        mixAndMatch[name] = value;
        this.setState({mixAndMatch: mixAndMatch});
    }

    delete = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.id)) {
            this.mixAndMatchService.delete(this.state.mixAndMatch.id)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let obj1 = Object.assign({}, defaultMixAndMatch);
                        this.setState({
                            mixAndMatch: Object.assign({}, obj1), mixAndMatchSaveButtonRender: false,
                            mixAndMatchTemp: Object.assign({}, obj1)
                        });
                        this.returnListPage();
                    }
                });
        }

        return undefined;
    }


    mixAndMatches() {

        return (<div class="p-col-12">
            <Fieldset legend="Mix And Match Details">
                <ModalConfirm header='Warning' ok='OK' onOkClick={this.delete}
                              onCancelClick={() => this.setState({displayDeleteConfirmDialog: false})}
                              show={this.state.displayDeleteConfirmDialog}
                              message={this.state.noticeResponse}/>

                <Row>
                    <Col md="12">
                        <Row>
                            <Col md="3">
                                <Fieldset legend="Status">
                                    <Col md="3">
                                    </Col>
                                    <Col md="9">
                                        <Row>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="status"
                                                disabled={true}
                                                value={this.state.mixAndMatch.status}
                                                onChange={this.__handleChange}>
                                                {STATUS.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>


                                            <ModalConfirm header='Warning' ok='OK' onOkClick={this.setCheckedStatus}
                                                          onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                                          show={this.state.displayShowStatusConfirmDialog}
                                                          message={this.state.noticeResponse}/>

                                            <InputSwitch
                                                checked={this.state.mixAndMatch.status == "ACTIVE" ? true : false}
                                                tooltip={"Change Status"}
                                                disabled={!this.state.mixAndMatchSaveButtonRender}
                                                onChange={(e) => this.showChangeStatusConfirmDialog(e.value)}/>
                                        </Row>

                                    </Col>

                                </Fieldset>

                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="mixAndMatch.tradingName">Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        disabled={!this.state.mixAndMatchSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.mixAndMatch.name}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Type</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.mixAndMatchSaveButtonRender}
                                        className="form-control"
                                        name="mixAndMatchType"
                                        value={this.state.mixAndMatch.mixAndMatchType}
                                        onChange={this.__handleChangeType}>
                                        {MIXMATCH_TYPE.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Level</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.mixAndMatchSaveButtonRender || !GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.id)}
                                        className="form-control"
                                        name="level"
                                        value={this.state.mixAndMatch.level}
                                        onChange={this.__handleChange}>
                                        {MIXMATCH_LEVEL.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Operation</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.mixAndMatchSaveButtonRender}
                                        className="form-control"
                                        name="operation"
                                        value={this.state.mixAndMatch.operation}
                                        onChange={this.__handleChange}>
                                        {MIXMATCH_OPERATION.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="producttradingName">Quantity</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>


                                    {this.state.mixAndMatch.level != 'BASKET' &&
                                        <InputNumber
                                            name="quantity"
                                            disabled={!this.state.mixAndMatchSaveButtonRender}
                                            value={this.state.mixAndMatch.quantity}
                                            onChange={this.__handleChange}
                                            className="table-responsive"
                                        />}

                                    {this.state.mixAndMatch.level == 'BASKET' &&
                                        <InputNumber
                                            name="quantity"
                                            disabled={!this.state.mixAndMatchSaveButtonRender}
                                            value={this.state.mixAndMatch.quantity}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />}
                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    {!(this.state.mixAndMatch.operation == 'FIXED_PRICE' || this.state.mixAndMatch.operation == 'DISCOUNT_AMOUNT') &&
                                        <Label for="tradingName">
                                            <Translate contentKey="amount">Amount(%)</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>

                                    }
                                    {(this.state.mixAndMatch.operation == 'FIXED_PRICE' || this.state.mixAndMatch.operation == 'DISCOUNT_AMOUNT') &&
                                        <Label for="tradingName">
                                            <Translate contentKey="amount">Amount</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                    }


                                    {!(this.state.mixAndMatch.operation == 'FIXED_PRICE' || this.state.mixAndMatch.operation == 'DISCOUNT_AMOUNT') &&

                                        <InputNumber
                                            name="amount"
                                            disabled={!this.state.mixAndMatchSaveButtonRender}
                                            value={this.state.mixAndMatch.amount}
                                            onChange={this.__handleChange}
                                            className="table-responsive"
                                        />}

                                    {(this.state.mixAndMatch.operation == 'FIXED_PRICE' || this.state.mixAndMatch.operation == 'DISCOUNT_AMOUNT') &&
                                        <InputNumber
                                            name="amount"
                                            disabled={!this.state.mixAndMatchSaveButtonRender}
                                            value={this.state.mixAndMatch.amount}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />}

                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">MM Print</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.mixAndMatchSaveButtonRender}
                                        className="form-control"
                                        name="mmPrint"
                                        value={this.state.mixAndMatch.mmPrint}
                                        onChange={this.__handleChange}>
                                        {MM_PRINT.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>



                            <Col md="3">

                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="Monday">Delivery&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("delivery", e.checked)}
                                              name="delivery"
                                              disabled={!this.state.mixAndMatchSaveButtonRender}
                                              value={this.state.mixAndMatch.delivery}
                                              checked={this.state.mixAndMatch.delivery}></Checkbox>
                                </AvGroup>
                            </Col>

                            <Col md="3">

                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="Monday">Collection&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("collection", e.checked)}
                                              name="collection"
                                              disabled={!this.state.mixAndMatchSaveButtonRender}
                                              value={this.state.mixAndMatch.collection}
                                              checked={this.state.mixAndMatch.collection}></Checkbox>
                                </AvGroup>
                            </Col>

                            <Col md="3">

                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="Monday">Till&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("till", e.checked)}
                                              name="till"
                                              disabled={!this.state.mixAndMatchSaveButtonRender}
                                              value={this.state.mixAndMatch.till}
                                              checked={this.state.mixAndMatch.till}></Checkbox>
                                </AvGroup>
                            </Col>
                            <Col md="3">

                            </Col>


                            <Col md="12">


                                    <Fieldset legend={"Schedule"}>
                                        <Row>

                                            <Col md="3">
                                                <AvGroup>
                                                    <Label for="tradingName">
                                                        <Translate
                                                            contentKey="merchantBranch.tradingName">Start Date</Translate>
                                                    </Label>

                                                    <DateInput
                                                        name="startDate"
                                                        onChange={this.__handleChange}
                                                        disabled={!this.state.mixAndMatchSaveButtonRender}
                                                        value={this.state.mixAndMatch.startDate}/>

                                                </AvGroup>

                                            </Col>

                                            <Col md="3">
                                                <AvGroup>
                                                    <Label for="tradingName">
                                                        <Translate
                                                            contentKey="merchantBranch.tradingName">End Date</Translate>
                                                    </Label>

                                                    <DateInput
                                                        name="endDate"
                                                        onChange={this.__handleChange}
                                                        disabled={!this.state.mixAndMatchSaveButtonRender}
                                                        value={this.state.mixAndMatch.endDate}/>

                                                </AvGroup>

                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md="1">

                                                <AvGroup>
                                                    <Label for="firstName">
                                                        <Translate contentKey="Monday">Monday&nbsp;</Translate>
                                                    </Label>

                                                    <Checkbox onChange={(e) => this.setChecked("monday", e.checked)}
                                                              name="monday"
                                                              disabled={!this.state.mixAndMatchSaveButtonRender}
                                                              value={this.state.mixAndMatch.monday}
                                                              checked={this.state.mixAndMatch.monday}></Checkbox>
                                                </AvGroup>
                                            </Col>

                                            <Col md="1">

                                                <AvGroup>
                                                    <Label for="firstName">
                                                        <Translate contentKey="Tuesday">Tuesday&nbsp;</Translate>
                                                    </Label>

                                                    <Checkbox onChange={(e) => this.setChecked("tuesday", e.checked)}
                                                              name="tuesday"
                                                              disabled={!this.state.mixAndMatchSaveButtonRender}
                                                              value={this.state.mixAndMatch.tuesday}
                                                              checked={this.state.mixAndMatch.tuesday}></Checkbox>
                                                </AvGroup>
                                            </Col>

                                            <Col md="1">

                                                <AvGroup>
                                                    <Label for="firstName">
                                                        <Translate contentKey="Wednesday">Wednesday&nbsp;</Translate>
                                                    </Label>

                                                    <Checkbox onChange={(e) => this.setChecked("wednesday", e.checked)}
                                                              name="wednesday"
                                                              disabled={!this.state.mixAndMatchSaveButtonRender}
                                                              value={this.state.mixAndMatch.wednesday}
                                                              checked={this.state.mixAndMatch.wednesday}></Checkbox>
                                                </AvGroup>
                                            </Col>

                                            <Col md="1">

                                                <AvGroup>
                                                    <Label for="firstName">
                                                        <Translate contentKey="Thursday">Thursday&nbsp;</Translate>
                                                    </Label>

                                                    <Checkbox onChange={(e) => this.setChecked("thursday", e.checked)}
                                                              name="thursday"
                                                              disabled={!this.state.mixAndMatchSaveButtonRender}
                                                              value={this.state.mixAndMatch.thursday}
                                                              checked={this.state.mixAndMatch.thursday}></Checkbox>
                                                </AvGroup>
                                            </Col>

                                            <Col md="1">

                                                <AvGroup>
                                                    <Label for="firstName">
                                                        <Translate contentKey="Friday">Friday&nbsp;</Translate>
                                                    </Label>

                                                    <Checkbox onChange={(e) => this.setChecked("friday", e.checked)}
                                                              name="friday"
                                                              disabled={!this.state.mixAndMatchSaveButtonRender}
                                                              value={this.state.mixAndMatch.friday}
                                                              checked={this.state.mixAndMatch.friday}></Checkbox>
                                                </AvGroup>
                                            </Col>

                                            <Col md="1">

                                                <AvGroup>
                                                    <Label for="firstName">
                                                        <Translate contentKey="Saturday">Saturday&nbsp;</Translate>
                                                    </Label>

                                                    <Checkbox onChange={(e) => this.setChecked("saturday", e.checked)}
                                                              name="saturday"
                                                              disabled={!this.state.mixAndMatchSaveButtonRender}
                                                              value={this.state.mixAndMatch.saturday}
                                                              checked={this.state.mixAndMatch.saturday}></Checkbox>
                                                </AvGroup>
                                            </Col>

                                            <Col md="1">

                                                <AvGroup>
                                                    <Label for="firstName">
                                                        <Translate contentKey="Sunday">Sunday&nbsp;</Translate>
                                                    </Label>

                                                    <Checkbox onChange={(e) => this.setChecked("sunday", e.checked)}
                                                              name="sunday"
                                                              disabled={!this.state.mixAndMatchSaveButtonRender}
                                                              value={this.state.mixAndMatch.sunday}
                                                              checked={this.state.mixAndMatch.sunday}></Checkbox>
                                                </AvGroup>
                                            </Col>


                                        </Row>

                                        <Row>


                                            <Col md="3">

                                                <AvGroup>
                                                    <Label for="firstName">
                                                        <Translate contentKey="Sunday">Start Hour</Translate>
                                                    </Label>

                                                    <InputNumber
                                                        name="startHour"
                                                        disabled={!this.state.mixAndMatchSaveButtonRender}
                                                        value={this.state.mixAndMatch.startHour}
                                                        onChange={this.__handleChange}
                                                        className="table-responsive"
                                                    />
                                                </AvGroup>
                                            </Col>

                                            <Col md="3">

                                                <AvGroup>
                                                    <Label for="firstName">
                                                        <Translate contentKey="Start Minute">Start Minute</Translate>
                                                    </Label>

                                                    <InputNumber
                                                        name="startMinute"
                                                        disabled={!this.state.mixAndMatchSaveButtonRender}
                                                        value={this.state.mixAndMatch.startMinute}
                                                        onChange={this.__handleChange}
                                                        className="table-responsive"
                                                    />
                                                </AvGroup>
                                            </Col>

                                            <Col md="3">

                                                <AvGroup>
                                                    <Label for="firstName">
                                                        <Translate contentKey="Duration">Duration</Translate>
                                                    </Label>

                                                    <InputNumber
                                                        name="duration"
                                                        disabled={!this.state.mixAndMatchSaveButtonRender}
                                                        value={this.state.mixAndMatch.duration}
                                                        onChange={this.__handleChange}
                                                        className="table-responsive"
                                                    />
                                                </AvGroup>
                                            </Col>


                                        </Row>
                                    </Fieldset>
                            </Col>




                        </Row>




                    </Col>


                    <Col md="12">
                        {this.state.mixAndMatch.mixAndMatchType == 'MIXMATCH' &&


                            <Fieldset legend={"Select Product"}>
                                <Row>
                                    <Col md="3">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="mixAndMatch.tradingName">Barcode</Translate>
                                            </Label>
                                            <AvField
                                                type="number"
                                                className="form-control"
                                                name="barcode"
                                                disabled={!this.state.mixAndMatchSaveButtonRender}
                                                max="99999999999999"
                                                onKeyDown={this._handleKeyDown}
                                                value={this.state.barcode}
                                                onChange={this.__handleChangeBarcode}/>


                                        </AvGroup>

                                    </Col>


                                    <Col md="3">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="mixAndMatch.tradingName">Name</Translate>
                                            </Label>
                                            <AutoComplete value={this.state.selectedProduct}
                                                          suggestions={this.state.filteredProducts}
                                                          completeMethod={this.searchProduct}
                                                          field="longname"
                                                          disabled={!this.state.mixAndMatchSaveButtonRender}
                                                          onChange={(e) => {
                                                              let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
                                                              this.setState({selectedProduct: value});
                                                              if (!GeneralUtils.isNullOrEmpty(e.value.id)) {
                                                                  this.setState({selectedProduct: e.value});
                                                                  let data = e.value;
                                                                  if (data.departmentExcludePromotion) {
                                                                      this.setState({selectedProduct: ''});
                                                                      return toast.warn("Product excluded from mixAndMatch");
                                                                  } else {
                                                                      let products = this.state.mixAndMatch.products;
                                                                      if (!this.isExistsInList(products, data)) {
                                                                          products.push(data);
                                                                          let mixAndMatch = this.state.mixAndMatch;
                                                                          mixAndMatch["products"] = products;
                                                                          this.setState({
                                                                              mixAndMatch: mixAndMatch,
                                                                              selectedProduct: ''
                                                                          });

                                                                      }
                                                                  }

                                                              }
                                                          }}

                                            />


                                        </AvGroup>
                                    </Col>


                                </Row>
                            </Fieldset>
                        }

                        <Fieldset legend="Product List">
                            <DataTableWithButton ref={(elem) => this.refDataTable = elem}
                                                 fields={ProductGroupProductColumn.fields}
                                                 objectName={ProductGroupProductColumn.name}
                                                 delete={this.deleteRow}
                                                 rows={10}
                                                 data={this.state.mixAndMatch.products}
                                                 loading={this.state.loading}/>


                        </Fieldset>

                    </Col>


                </Row>


                {!GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.id) && this.state.mixAndMatchSaveButtonRender == false && GeneralUtils.checkPrivilege("post_product/mixandmatch") &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({mixAndMatchSaveButtonRender: true});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_product/mixandmatch") && this.state.mixAndMatchSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveMixAndMatch();
                    }}>
                        <Translate contentKey="entity.action.add">Save</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("delete_product/mixandmatch/_id") && this.state.mixAndMatchSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.id) &&

                    <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                        this.showDeleteConfirmDialog(true)
                    }}>
                        <Translate contentKey="entity.action.add">Delete</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_product/mixandmatch") && this.state.mixAndMatchSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        let obj1 = this.state.mixAndMatch;
                        if (GeneralUtils.isNullOrEmpty(obj1.id)) {
                            this.returnListPage()
                        } else {
                            this.mixAndMatchService.findById(obj1.id).then(response => {
                                this.setState({
                                    mixAndMatch: Object.assign({}, response),
                                    mixAndMatchSaveButtonRender: false,
                                    barcode: '',
                                    selectedProduct: '',
                                    mixAndMatchTemp: Object.assign({}, response)
                                });
                            });
                        }

                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>}
            </Fieldset>


        </div>)
    };

    searchProduct = (event) => {
        setTimeout(() => {
            this.productService.searchProductsByName(event.query).then(response => {
                this.setState({filteredProducts: response});

            });
        }, 250);
    }

    deleteRow = (rowData) => {
        if (this.state.mixAndMatchSaveButtonRender) {
            let products = this.state.mixAndMatch.products;
            let newProducts = this.arrayRemove(products, rowData);

            let mixAndMatch = this.state.mixAndMatch;
            mixAndMatch["products"] = newProducts;
            this.setState({mixAndMatch: mixAndMatch});
        }


    }

    arrayRemove = (arr, element) => {
        return arr.filter(function (el) {
            return el.id != element.id;
        });
    }

    showDeleteConfirmDialog = (value) => {
        let defaultStatus = 'PASSIVE';
        if (value) {
            defaultStatus = 'ACTIVE'
        }
        this.setState({
            displayDeleteConfirmDialog: true,
            defaultStatus: defaultStatus,
            noticeResponse: "Are you sure to delete?"
        });
    }

    saveMixAndMatch = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.name) ||
            GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.mixAndMatchType) ||
            GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.level) ||
            GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.operation) ||
            GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.quantity) ||
            GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.amount)

        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.mixAndMatch.id)) {
                this.mixAndMatchService.update(this.state.mixAndMatch)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                mixAndMatchSaveButtonRender: false,
                                mixAndMatch: Object.assign({}, response),
                                mixAndMatchTemp: Object.assign({}, response)

                            });
                            this.returnListPage();
                        }
                    });
            } else {
                this.mixAndMatchService.create(this.state.mixAndMatch)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                mixAndMatchSaveButtonRender: false,
                                mixAndMatch: Object.assign({}, response),
                                mixAndMatchTemp: Object.assign({}, response)
                            });
                            this.returnListPage();
                        }
                    });
            }
        }
    }

    addNew() {
        let obj1 = Object.assign({}, defaultMixAndMatch);
        this.setState({
            mixAndMatch: obj1, mixAndMatchSaveButtonRender: true, barcode: '', selectedProduct: ''
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let mixAndMatch = this.state.mixAndMatch;
        mixAndMatch[e.target.name] = value;
        this.setState({mixAndMatch: mixAndMatch});
    }

    __handleChangeType = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let mixAndMatch = this.state.mixAndMatch;
        if (value != 'MIXMATCH') {
            mixAndMatch['products'] = [];
        }
        mixAndMatch[e.target.name] = value;
        this.setState({mixAndMatch: mixAndMatch});
    }

    findByBarcode() {

        if (!GeneralUtils.isNullOrEmpty(this.state.barcode)) {
            this.productService.findProductByBarcode(this.state.barcode)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let data = response;

                        if (data.departmentExcludePromotion) {
                            return toast.warn("Product excluded from mixAndMatch");
                        } else {
                            let products = this.state.mixAndMatch.products;
                            if (!this.isExistsInList(products, data)) {
                                products.push(data);
                                let mixAndMatch = this.state.mixAndMatch;
                                mixAndMatch["products"] = products;
                                this.setState({mixAndMatch: mixAndMatch});

                            }
                        }


                    } else {
                        return toast.warn("Barcode not Found!");
                    }

                });
        }
        this.setState({barcode: ''});

    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.findByBarcode();
        }
    }


    __handleChangeBarcode = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({barcode: value});
    }

    isExistsInList = (assetList, data) => {
        var existsInList = false;
        for (var i = 0; i < assetList.length; i++) {
            if (data.id == assetList[i].id) {
                existsInList = true;
            }
        }
        return existsInList;
    }

}

