import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import MerchantAccountService from "../../shared/service/MerchantAccountService";
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {PhoneField} from "../../shared/component/form/PhoneField";
import {toast} from "react-toastify";
import MerchantBranchColumn from "../../shared/component/dataTable/columns/MerchantBranchColumn.json";
import {STATUS} from "../../shared/utils/Constants";
import {InputSwitch} from "primereact/inputswitch";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import DataTable from "../../shared/component/dataTable/DataTable";


const defaultMerchantAccount = {
    id: '',
    tradingName: '',
    businessName: '',
    companyNo: '',
    vatNo: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    emailAddress: '',
    status: 'ACTIVE',
    verified: false
};
export default class MerchantAccountSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            merchantAccount: defaultMerchantAccount,
            merchantAccountTemp: defaultMerchantAccount,
            accountDetailsSaveButtonRender: true,
            accountDetailsContactSaveButtonRender: false,
            branchDetailsSaveButtonRender: false,
            licenseSaveButtonRender: false,
            renderBranches: false,
            branchId: '',
            merchantStatus: 'ACTIVE',
            branchList: [],
            licenseId: '',
            licenseList: [],
            activeIndex: 0
        }
        this.merchantAccountService = new MerchantAccountService();
    }

    componentDidMount() {

        let obj1 = Object.assign({}, defaultMerchantAccount);
        this.setState({
            merchantAccount: obj1,
            merchantAccountTemp: Object.assign({}, obj1)
        });
        let id = window.location.href.split('?')[1];
        if (!GeneralUtils.__isValueEmpty(id)) {
            this.getAccountDetails(id);
            this.refresh(id);
        }

    }

    render() {
        return (<div>
            <AvForm>
                {this.merchantAccountDetails()}
                {this.merchantBranchesList()}

            </AvForm>
        </div>);
    }

    merchantBranchesList() {
        let accountId;
        if (!GeneralUtils.isNullOrEmpty(window.location.href.split('?')[1])) {
            accountId = window.location.href.split('?')[1]
        } else {
            accountId = this.state.merchantAccount.id;
        }
        var url = "crm/merchant/branch/" + accountId + "/branches";
        return (this.state.renderBranches && <div className="p-col-12"><Fieldset legend="Branches">
            <Button color="outline-primary" style={{marginBottom: '5px'}} onClick={() => {
                window.location.href = "#merchantBranchSave?" + "" + "?" + this.state.merchantAccount.id;
            }}>
                <Translate contentKey="entity.action.add">Add</Translate>
            </Button>
            <DataTable fields={MerchantBranchColumn.fields}
                       ref={(elem) => this.refDataTable = elem}
                       dataURL={url}
                       selectionMode="single" onDataSelect={this.onDataSelect}
                       loading={this.state.loading}/>

        </Fieldset></div>)
    }

    setCheckedStatus = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.merchantAccount.id)) {
            this.merchantAccountService.updateStatus(this.state.merchantAccount.id, this.state.merchantStatus)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            merchantAccount: response,
                            merchantAccountTemp: Object.assign({}, response),
                            accountDetailsSaveButtonRender: false
                        });

                    }
                });
        } else {
            let merchantAccount = this.state.merchantAccount;
            merchantAccount["status"] = this.state.merchantAccount.status == "ACTIVE" ? "PASSIVE" : "ACTIVE";
            this.setState({merchantAccount: merchantAccount, merchantAccountTemp: Object.assign({}, merchantAccount)});
        }

        return undefined;
    }

    showChangeStatusConfirmDialog = (value) => {
        let merchantStatus = 'PASSIVE';
        if (value) {
            merchantStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            merchantStatus: merchantStatus,
            noticeResponse: "You are about to change the status. Are you sure?"
        });
    }

    merchantAccountDetails() {

        return (<div class="p-col-12">
            <Row>
                <Col md="4">
                    <Fieldset legend="Account Details">
                        <ModalConfirm header='Warning' ok='OK' onOkClick={this.delete}
                                      onCancelClick={() => this.setState({displayShowDeleteConfirmDialog: false})}
                                      show={this.state.displayShowDeleteConfirmDialog}
                                      message={this.state.noticeResponse}/>

                        <Col md="12">
                            <Fieldset legend="Status">
                                <Col md="3">
                                </Col>
                                <Col md="9">
                                    <Row>
                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="status"
                                            disabled={true}
                                            value={this.state.merchantAccount.status}
                                            onChange={this.__handleChange}>
                                            {STATUS.map(element => (<option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                        </AvField>


                                        <ModalConfirm header='Warning' ok='OK' onOkClick={this.setCheckedStatus}
                                                      onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                                      show={this.state.displayShowStatusConfirmDialog}
                                                      message={this.state.noticeResponse}/>

                                        <InputSwitch
                                            checked={this.state.merchantAccount.status == "ACTIVE" ? true : false}
                                            tooltip={"Change Status"}
                                            disabled={!this.state.accountDetailsSaveButtonRender}
                                            onChange={(e) => this.showChangeStatusConfirmDialog(e.value)}/>
                                    </Row>

                                </Col>
                            </Fieldset>

                        </Col>

                        <Col md="12">
                            <AvGroup>
                                <Label for="companyNo">
                                    <Translate contentKey="brand.name">Company No</Translate>
                                    <span style={{ color: 'red' }}> (*)</span>
                                </Label>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <AvField
                                        type="number"
                                        className="form-control"
                                        name="companyNo"
                                        disabled={!this.state.accountDetailsSaveButtonRender}
                                        min="1"
                                        max="9999999999"
                                        value={this.state.merchantAccount.companyNo}
                                        onChange={this.__handleChange}
                                        style={{ flex: 100 }} // Ensure the field occupies a larger space
                                    />
                                    <span
                                        className="pi pi-search"
                                        disabled={!this.state.accountDetailsSaveButtonRender}
                                        style={{
                                            fontSize: '1.75rem',
                                            cursor: 'pointer',
                                            flex: 1, // The search button remains smaller
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                        }}
                                        onClick={(e) => {
                                            this.getCompanyInfo();
                                        }}
                                    ></span>
                                </div>
                            </AvGroup>
                        </Col>

                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="merchantAccount.tradingName">Trading Name</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="tradingName"
                                    disabled={!this.state.accountDetailsSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.merchantAccount.tradingName}
                                    onChange={this.__handleChange}/>

                            </AvGroup>
                        </Col>
                        <Col md="12">
                            <AvGroup>
                                <Label for="businessName">
                                    <Translate contentKey="brand.name">Business Name</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="businessName"
                                    disabled={!this.state.accountDetailsSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.merchantAccount.businessName}
                                    onChange={this.__handleChange}/>

                            </AvGroup>
                        </Col>




                        <Col md="12">
                            <AvGroup>
                                <Label for="vatNo">
                                    <Translate contentKey="brand.name">Vat No</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="vatNo"
                                    disabled={!this.state.accountDetailsSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.merchantAccount.vatNo}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>

                        <Col md="12">
                            {this.state.accountDetailsSaveButtonRender == false &&
                                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                    this.setState({accountDetailsSaveButtonRender: true});
                                }}>
                                    <Translate contentKey="entity.action.add">Edit</Translate>
                                </Button>}

                            {this.state.accountDetailsSaveButtonRender &&
                                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {

                                    this.saveMerchantAccountDetails();
                                }}>
                                    <Translate contentKey="entity.action.add">Save</Translate>
                                </Button>
                            }


                            {this.state.accountDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.merchantAccount.id) &&

                                <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                                    this.showChangeDeleteConfirmDialog(true)
                                }}>
                                    <Translate contentKey="entity.action.add">Delete</Translate>
                                </Button>}


                            {this.state.accountDetailsSaveButtonRender &&
                                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                    let obj1 = this.state.merchantAccountTemp;
                                    if (GeneralUtils.isNullOrEmpty(obj1.id)) {
                                        window.history.back();
                                    } else {
                                        this.setState({
                                            merchantAccount: Object.assign({}, obj1),
                                            accountDetailsSaveButtonRender: false
                                        });
                                    }

                                }}>
                                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                                </Button>
                            }
                        </Col>

                    </Fieldset>
                </Col>


                <Col md="4">



                    {this.state.merchantAccount.id != '' &&

                        <Fieldset legend="Account Contact Details">
                            <Col md="12">

                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="merchantAccount.firstName">First Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        disabled={!this.state.accountDetailsContactSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantAccount.firstName}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>

                            <Col md="12">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="merchantAccount.lastName">Last Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="lastName"
                                        disabled={!this.state.accountDetailsContactSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantAccount.lastName}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>

                            <Col md="12">
                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Email</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="email"
                                        className="form-control"
                                        name="emailAddress"
                                        disabled={!this.state.accountDetailsContactSaveButtonRender}
                                        validate={{
                                            minLength: {
                                                "value": 5, "errorMessage": "Not Valid Email"
                                            }, maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}

                                        value={this.state.merchantAccount.emailAddress}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>

                            <Col md="12">

                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Mobile
                                            Phone</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <PhoneField kind={'mobile'}
                                                disabled={!this.state.accountDetailsContactSaveButtonRender}
                                                name="mobilePhone" errorMessage="Not Valid Phone Number"
                                                value={this.state.merchantAccount.mobilePhone}
                                                onChange={this.__handleChange}
                                    />
                                </AvGroup>
                            </Col>

                            <Col md="12">
                                {this.state.accountDetailsContactSaveButtonRender == false &&
                                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                        this.setState({
                                            accountDetailsContactSaveButtonRender: true
                                        });

                                    }}>
                                        <Translate contentKey="entity.action.add">Edit</Translate>
                                    </Button>}
                                {this.state.accountDetailsContactSaveButtonRender &&
                                    <Button type="submit" color="outline-primary" style={{marginRight: '5px'}}
                                            onClick={() => {
                                                this.saveMerchantAccountContact();

                                            }}>
                                        <Translate contentKey="entity.action.add">Save</Translate>
                                    </Button>}

                                {this.state.accountDetailsContactSaveButtonRender &&
                                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {

                                        let obj1 = this.state.merchantAccountTemp;
                                        this.setState({
                                            merchantAccount: Object.assign({}, obj1),
                                            accountDetailsContactSaveButtonRender: false
                                        });


                                    }}>
                                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                                    </Button>
                                }
                            </Col>
                        </Fieldset>}

                </Col>
            </Row>

        </div>)
    };

    showChangeDeleteConfirmDialog = (value) => {
        this.setState({
            displayShowDeleteConfirmDialog: true,
            noticeResponse: "Are you sure to delete?"
        });
    }

    delete = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.merchantAccount.id)) {
            this.merchantAccountService.delete(this.state.merchantAccount.id)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        window.location.href = '#merchantAccountList'
                    }
                });
        }

        return undefined;
    }

    saveMerchantAccountContact = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.merchantAccount.firstName) || GeneralUtils.isNullOrEmpty(this.state.merchantAccount.lastName) || GeneralUtils.isNullOrEmpty(this.state.merchantAccount.mobilePhone) || GeneralUtils.isNullOrEmpty(this.state.merchantAccount.emailAddress)) {
            return toast.warn("Please fill the required fields!");
        } else {
            var contactData = {
                firstName: this.state.merchantAccount.firstName,
                lastName: this.state.merchantAccount.lastName,
                mobilePhone: this.state.merchantAccount.mobilePhone,
                emailAddress: this.state.merchantAccount.emailAddress
            };
            this.merchantAccountService.updateContact(this.state.merchantAccount.id, contactData)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            accountDetailsContactSaveButtonRender: false, renderBranches: true

                        });
                    }
                });
        }

    }

    refresh = (id) => {
        this.setState({
            renderBranches: true
        });


    }
    onDataSelect = (data) => {
        window.location.href = "#merchantBranchSave?" + data.id + "?" + data.merchantAccountId;
    }


    saveMerchantAccountDetails = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.merchantAccount.tradingName) || GeneralUtils.isNullOrEmpty(this.state.merchantAccount.companyNo) || GeneralUtils.isNullOrEmpty(this.state.merchantAccount.businessName)) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.merchantAccount.id)) {
                this.merchantAccountService.update(this.state.merchantAccount)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                accountDetailsSaveButtonRender: false,
                                accountDetailsContactSaveButtonRender: false,
                                renderBranches: true,
                                merchantAccount: response,
                                merchantAccountTemp: Object.assign({}, response)

                            });
                        }
                    });
            } else {
                this.merchantAccountService.create(this.state.merchantAccount)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            let merchantAccount = this.state.merchantAccount;
                            merchantAccount.id = response.id;
                            this.setState({
                                accountDetailsSaveButtonRender: false, accountDetailsContactSaveButtonRender: true,
                                merchantAccount: response,
                                merchantAccountTemp: Object.assign({}, response)
                            });
                            if (response.verified == true) {
                                this.setState({
                                    renderBranches: true,
                                    accountDetailsContactSaveButtonRender: false,

                                });
                            }
                        }
                    });
            }
        }

    }

    getCompanyInfo = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.merchantAccount.companyNo)) {
            return toast.warn("Please fill the company no");
        } else {
            this.merchantAccountService.getCompanyInfo(this.state.merchantAccount.companyNo).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if (GeneralUtils.isNullOrEmpty(response.company_name)) {
                        let merchantAccount = this.state.merchantAccount;
                        merchantAccount["verified"] = false;
                        this.setState({merchantAccount: merchantAccount});
                        return toast.warn("Company Not Found");
                    } else {
                        let merchantAccount = this.state.merchantAccount;
                        merchantAccount["tradingName"] = response.company_name;
                        merchantAccount["businessName"] = response.company_name;
                        merchantAccount["firstName"] = response.contact.firstName;
                        merchantAccount["lastName"] = response.contact.lastName;
                        merchantAccount["verified"] = true;
                        merchantAccount["addressLine1"] = response.address.address_line_1;
                        merchantAccount["locality"] = response.address.locality;
                        merchantAccount["postCode"] = response.address.postal_code;
                        this.setState({merchantAccount: merchantAccount});
                    }

                }

            });
        }


    }

    getAccountDetails = (id) => {

        this.merchantAccountService.findById(id).then(response => {
            this.setState({
                merchantAccount: response,
                merchantAccountTemp: Object.assign({}, response),
            });

        });

        this.setState({
            accountDetailsSaveButtonRender: false,
            renderBranches: true
        });

    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let merchantAccount = this.state.merchantAccount;
        merchantAccount[e.target.name] = value;
        this.setState({merchantAccount: merchantAccount});
    }

}

