import {SERVER_URL} from '../utils/Constants';
import GeneralUtils from "../utils/GeneralUtils";
import {toast} from "react-toastify";

export default class ServiceMember {
    constructor(props) {
        this.baseURL = props;
    }

    async findAll() {
        return fetch(SERVER_URL + this.baseURL, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notificationPayNoAttention(error);
        });
    }

    async findAllByMerchantBranchId(merchantBranchId) {
        return fetch(SERVER_URL + this.baseURL + "/merchantBranchId/" + merchantBranchId, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findById(id) {
        return fetch(SERVER_URL + this.baseURL + "/"+id, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findByBranchId(branchId) {
        return fetch(SERVER_URL + this.baseURL + "/all/" + branchId, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findAllActive() {
        return fetch(SERVER_URL + this.baseURL + "/status/active", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findAllActiveForCombo() {
        return fetch(SERVER_URL + this.baseURL + "/status/active/combo", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async create(data) {
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async createHiddenSuccess(data) {
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async update(data) {
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
                let result = response.clone();
                GeneralUtils.notification(response);
                return GeneralUtils.responseToJson(result);
            }
        ).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async delete(id) {
        return fetch(SERVER_URL + this.baseURL +"/"+ id, {
            crossDomain: true,
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updateStatus(id, status) {
        return fetch(SERVER_URL + this.baseURL + "/"+ id + "/status/" + status, {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }




}