import React from 'react';
import {AvForm} from 'availity-reactstrap-validation';
import OrdersColumn from "../../shared/component/dataTable/columns/OrdersColumn.json";
import {Fieldset} from "primereact/fieldset";
import ReactCountdownClock from "react-countdown-clock";
import {Row} from "reactstrap";
import Dialog from "../../shared/component/dialog/Dialog";
import OrdersDetail from "./OrdersDetail";
import actionRequiredSound from './actionRequired.wav'
import DataTable from "../../shared/component/dataTable/DataTable";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import OrderService from "../../shared/service/OrderService";
import DataTableAll from "../../shared/component/dataTable/DataTableAll";

export default class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds:10,
            maxOrderDateLong:null,
            orderList:[]
        };
        this.orderService = new OrderService();
    }

    componentDidMount() {
        this.getNewOrders();
    }

    getNewOrders(){

        let scope = null;
        if(localStorage.getItem("scope") && !GeneralUtils.isNullOrEmpty(localStorage.getItem("scope"))){
           scope =  localStorage.getItem("scope");
        }
        this.orderService.getOpenOrders(scope).then(response => this.setState({
            orderList: response
        }));





        //this.refDataTable.onPage();
        var dataList = this.state.orderList;
        for(var i = 0 ; i < dataList.length ; i++){
                if(dataList[i].read == false){
                    let audio = new Audio(actionRequiredSound);
                    audio.play();
                    break;
                }
        }
        this.setState({
            seconds : this.state.seconds + 0.00000001
        });
    }

    render() {
        return (<div>
            <AvForm>
                {this.newOrderList()}
            </AvForm>
        </div>);
    }

    countDownClock = () => {
        this.getNewOrders();
    }

    newOrderList() {

        let dataUrl = "dispatchio/order/newOrders";
        if(localStorage.getItem("scope") && !GeneralUtils.isNullOrEmpty(localStorage.getItem("scope"))){
            dataUrl =  dataUrl + "/" + localStorage.getItem("scope");
        }
        else{
            dataUrl =  dataUrl + "/" + null;
        }
        return (<div>
            <Row className="justify-content-center">
                    <ReactCountdownClock seconds={this.state.seconds}
                                         color="#1a1a1a"
                                         showMilliseconds={false}
                                         alpha={0.9}
                                         size={50}
                                         onComplete={this.countDownClock}/>
            </Row>
            <Fieldset legend="Open Orders">
                <DataTableAll  fields={OrdersColumn.fields}
                           objectName={OrdersColumn.name}
                              data={this.state.orderList}
                           responsive={false}
                           rows={10}
                           emptyMessage="Not any order"
                           selectionMode="single" onDataSelect={this.onDataSelect}
                           loading={this.state.loading}/>


                <Dialog visible={this.state.displayDialog}
                        header={this.state.orderNo == null ? "Order Details" :this.state.orderNo}
                        onHide={() => this.setState({displayDialog: false})} style={GeneralUtils.getDialogStyleMerchantForDialog()}>

                    <OrdersDetail refreshDataTable={this.refreshDataTable} ref={(elem) => this.refModelSave = elem}
                                  closeDisplayDialog={this.refreshDataTable}/>
                </Dialog>
            </Fieldset>


        </div>)
    };

    refreshDataTable = () => {
        this.closeDisplayDialog();
        this.getNewOrders();
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false, orderNo:null});
    }

    onDataSelect = (data) => {
        this.refModelSave.setData(Object.assign({}, data));
        this.setState({
            orderNo:data.orderNo,
            displayDialog: true
        });
    }


}

