import React from 'react';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Fieldset} from "primereact/fieldset";
import BasketService from "../../shared/service/BasketService";
import {Button, ButtonGroup, Col, Label, Row} from "reactstrap";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {OrderCheckOut} from "../../shared/layaout/sigma/OrderCheckOut";
import MerchantBranchSettingsService from "../../shared/service/MerchantBranchSettingsService";
import Translate from "../../shared/language/Translate";
import {PAGE_MODE, SERVER_URL} from "../../shared/utils/Constants";
import AvField from "../../shared/component/form/AvField";
import {Checkbox} from "primereact/checkbox";
import {toast} from "react-toastify";

import Loading from "../../shared/component/others/Loading";
import {RadioButton} from "primereact/radiobutton";
import RestTableService from "../../shared/service/RestTableService";
import PaymentService from "../../shared/service/PaymentService";
import './dojoButtons.css';
import AddressService from "../../shared/service/AddressService";
import MerchantBranchService from "../../shared/service/MerchantBranchService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {PhoneField} from "../../shared/component/form/PhoneField";
import {InputTextarea} from "primereact/inputtextarea";
import {InputMask} from "primereact/inputmask";

const defaultOrder = {
    id: '', tableNo: null, saveCard: false, orderType: "Table Order"
};
const defaultMemberUserCard = {
    id: null,
    expireDate: null,
    cvv: null,
    cardNo: '',
    cardHolderName: '',
    defaultCard: true,
    paymentType: "CARD",
    saveCard: false,
    cardType: 'VISA',
};

const defaultMemberUserAddress = {
    id: null,
    description: '',
    postCode: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    defaultAddress: true,
    saveAddress: false
};

const defaultMemberBillingAddress = {
    id: null,
    description: '',
    postCode: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    defaultAddress: true,
    saveBillingAddress: false
};

export default class ConfirmOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentSettings: [],
            filteredAddresses: [],
            billingAddressList: [],
            filteredBillingAddresses: [],
            changeDeliveryAddressDisabled: true,
            isClosed: false, openHourMessage: null,
            tables: [],
            sameAsDeliveryAddress: false,
            showDeliveryAddressDetails: false,
            showBillingAddressDetails: false,
            deliveryTypesList: [],
            order: Object.assign({}, defaultOrder),
            memberUserCard: defaultMemberUserCard,
            memberUserAddress: defaultMemberUserAddress,
            memberUserAddressTemp: Object.assign({}, defaultMemberUserAddress),
            memberBillingAddress: defaultMemberBillingAddress,
            memberBillingAddressTemp: Object.assign({}, defaultMemberBillingAddress),
            confirmButtonName: 'Confirm Order',
            loading: false,
            confirmButtonDisabled: false
        }
        this.onClickConfirmOrderButton = this.onClickConfirmOrderButton.bind(this);
        this.basketService = new BasketService();
        this.restTableService = new RestTableService();
        this.paymentService = new PaymentService();
        this.merchantBranchSettingsService = new MerchantBranchSettingsService();
        this.addressService = new AddressService();
        this.merchantBranchService = new MerchantBranchService();
    }

    componentWillUnmount() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            this.reloadPage("")
        });
    }

    async componentDidMount() {
        const response = (await this.merchantBranchSettingsService.getPaymentSettingsInfo(localStorage.getItem("merchantBranchId")));
        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
            let order = this.state.order;
            order["provider"] = response.provider;
            this.setState({order: order});
            if (!GeneralUtils.isNullOrEmpty(response.paymentSenseUrl)) {
                const script = document.createElement("script");
                script.src = response.paymentSenseUrl;
                script.async = true;
                document.body.appendChild(script);
            }
        }
        this.getDeliveryInformation();
        await this.getDeliveryTypes();
        await this.getPaymentSettings();

        let that = this;
        document.getElementById('apple-pay-button').addEventListener('click', function (e) {
            that.onClickConfirmOrderButton()
        });

    }

    async getDeliveryTypes() {
        var array = [];
        let orderType = null;
        var hasDelivery = false;
        const response = (await this.merchantBranchSettingsService.findByBranchIdForMember(localStorage.getItem("merchantBranchId")));
        if (localStorage.getItem("WEB_ORDER_LICENSE") == "true") {

            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
                if (!GeneralUtils.isNullOrEmpty(response.webOrderPaymentOptions)) {

                    var nameArr = response.webOrderPaymentOptions.split(',');
                    for (var i = 0; i < nameArr.length; i++) {
                        if (nameArr[i].indexOf("DELIVERY") > 0) {
                            array.push({
                                name: "Delivery",
                                id: "Delivery"
                            });
                            orderType = "Delivery";
                            hasDelivery = true;
                            this.setState({
                                sameAsDeliveryAddress: true
                            });
                            break;
                        }

                    }

                    for (var i = 0; i < nameArr.length; i++) {
                        if (nameArr[i].indexOf("COLLECTION") > 0) {
                            array.push({
                                name: "Collection",
                                id: "Collection"
                            });

                            if (hasDelivery) {
                                orderType = "Delivery";
                                this.setState({
                                    sameAsDeliveryAddress: true
                                });
                            } else {
                                orderType = "Collection";
                                this.setState({
                                    sameAsDeliveryAddress: false
                                });
                            }

                            break;
                        }

                    }

                }
            }


        }

        if (localStorage.getItem("TABLE_ORDER_LICENSE") == "true") {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
                if (!GeneralUtils.isNullOrEmpty(response.tableOrderPaymentOptions)) {
                    array.push({
                        name: "Table Order",
                        id: "Table Order"
                    });
                    orderType = "Table Order";
                    this.setState({
                        sameAsDeliveryAddress: false
                    });
                    if (GeneralUtils.isNullOrEmpty(localStorage.getItem("tableNo")) && localStorage.getItem("WEB_ORDER_LICENSE") == "true") {
                        if (hasDelivery) {
                            orderType = "Delivery";
                            this.setState({
                                sameAsDeliveryAddress: true
                            });
                        } else {
                            orderType = "Collection";
                            this.setState({
                                sameAsDeliveryAddress: false
                            });
                        }
                    }
                }
            }

        }
        //this.getBasketInfo(orderType);
        let order = this.state.order;
        if (!GeneralUtils.isNullOrEmpty(response.scheduledForDelivery)) {
            order["scheduledForDelivery"] = response.scheduledForDelivery;
        } else {
            order["scheduledForDelivery"] = false;
        }
        order["orderTimeForDeliveryList"] = response.orderTimeForDelivery;
        order["orderType"] = orderType;
        this.setState({order: order, deliveryTypesList: array});
        this.setOrderTypeAndAddresFromMemberPage();
    }

    setAddressToState(itemSub) {
        let address = {};
        address["postCode"] = itemSub.postCode;
        address["description"] = !GeneralUtils.isNullOrEmpty(itemSub.description) ? itemSub.description.replace(" (Default Address)", "") : itemSub.postCode;
        address["addressLine1"] = itemSub.addressLine1;
        address["addressLine2"] = itemSub.addressLine2;
        address["addressLine3"] = itemSub.addressLine3;
        address["addressLine4"] = itemSub.addressLine4;
        address["city"] = itemSub.city;
        return address;
    }

    getDeliveryInformation() {
        this.restTableService.findByMerchantBranchId(localStorage.getItem("merchantBranchId")).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({tables: response, changeDeliveryAddressDisabled: true});
            }
        });

        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("tableNo"))) {
            let order = this.state.order;
            order["tableNo"] = localStorage.getItem("tableNo");
            this.setState({order: order});
        }


    }

    setDefaultServiceChargeOptions(orderType, data) {
        let defaultServiceChargeOptions = null;
        if (orderType == "Table Order") {
            defaultServiceChargeOptions = data.serviceChargeOptionsTableOrder;
        } else if (orderType == "Delivery") {
            defaultServiceChargeOptions = data.serviceChargeOptionsDelivery;
        } else if (orderType == "Collection") {
            defaultServiceChargeOptions = data.serviceChargeOptions;
        }
        return defaultServiceChargeOptions;

    }

    setDefaultServiceCharge(orderType, data) {
        let defaultServiceCharge = null;
        if (orderType == "Table Order") {
            defaultServiceCharge = data.defaultServiceChargeTableOrder;
        } else if (orderType == "Delivery") {
            defaultServiceCharge = data.defaultServiceChargeDelivery;
        } else if (orderType == "Collection") {
            defaultServiceCharge = data.defaultServiceCharge;
        }
        return defaultServiceCharge;

    }

    async getPaymentSettings() {

        const response = (await this.merchantBranchSettingsService.findByBranchIdForMember(localStorage.getItem("merchantBranchId")));

        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
            if (this.state.order.orderType == "Table Order") {
                var arrayList = new Array();
                if (!GeneralUtils.isNullOrEmpty(response.tableOrderPaymentOptions)) {
                    var nameArr = response.tableOrderPaymentOptions.split(',');

                    for (var i = 0; i < nameArr.length; i++) {
                        let data1 = {
                            id: nameArr[i], name: nameArr[i]
                        };
                        if (nameArr[i] == "CARD") {
                            arrayList.push(data1);
                            this.getPaymentOption("CARD")
                        }
                        if (data1.name.indexOf("APPLE PAY") >= 0) {
                            if (window.ApplePaySession) {
                                arrayList.push(data1);
                            }
                        }

                    }
                }

                this.setState({
                    paymentSettings: arrayList,
                    minimumDeliveryBasketPrice: response.minimumDeliveryBasketPrice,
                    isClosed: response.closed,
                    openHourMessage: response.openHourMessage,
                    serviceChargeOptions: this.setDefaultServiceChargeOptions(this.state.order.orderType, response),
                    defaultServiceCharge: this.setDefaultServiceCharge(this.state.order.orderType, response),
                })

                if (this.refOrderCheckout != null) {
                    this.refOrderCheckout.setBranchSettings(this.state.order.orderType, this.state.minimumDeliveryBasketPrice, this.state.isClosed, this.state.serviceChargeOptions);
                }
            } else if ((this.state.order.orderType == "Delivery" || this.state.order.orderType == "Collection") && !GeneralUtils.isNullOrEmpty(response.webOrderPaymentOptions)) {
                var nameArr = response.webOrderPaymentOptions.split(',');
                var arrayList = new Array();
                for (var i = 0; i < nameArr.length; i++) {
                    if (nameArr[i].indexOf(this.state.order.orderType.toUpperCase()) > 0) {
                        let data1 = {
                            id: nameArr[i].replace(" (DELIVERY)", "").replace(" (COLLECTION)", ""),
                            name: nameArr[i].replace(" (DELIVERY)", "").replace(" (COLLECTION)", "")
                        };
                        if (data1.name == "CARD") {
                            arrayList.push(data1);
                            this.getPaymentOption("CARD")
                        }
                        if (data1.name.indexOf("APPLE PAY") >= 0) {
                            if (window.ApplePaySession) {
                                arrayList.push(data1);
                            }
                        }
                    }

                }
                this.setState({
                    paymentSettings: arrayList,
                    minimumDeliveryBasketPrice: response.minimumDeliveryBasketPrice,
                    isClosed: response.closed,
                    openHourMessage: response.openHourMessage,
                    serviceChargeOptions: this.setDefaultServiceChargeOptions(this.state.order.orderType, response),
                    defaultServiceCharge: this.setDefaultServiceCharge(this.state.order.orderType, response),
                })
                if (this.refOrderCheckout != null) {
                    this.refOrderCheckout.setBranchSettings(this.state.order.orderType, this.state.minimumDeliveryBasketPrice, this.state.isClosed, this.state.serviceChargeOptions);
                }
            }
        }
    }

    render() {
        if (!GeneralUtils.isMobile()) {
            return (<div>
                <AvForm>
                    <div>
                        <Row>
                            <Col md={2}>
                            </Col>
                            <Col md={4}>
                                {this.renderDelivery()}
                                {this.renderPayment()}
                            </Col>
                            {(GeneralUtils.hasWebOrderLicense() || GeneralUtils.hasTableOrderLicense()) &&
                                <Col md={4}>
                                    {this.renderCheckOut()}
                                </Col>}
                            <Col md={2}>
                            </Col>
                        </Row>

                    </div>
                </AvForm>
                {this.state.loading && <Loading/>}
            </div>);
        } else {
            return (<div>
                <AvForm>
                    <div>
                        <Row>
                            <Col md={4}>
                                {this.renderDelivery()}
                                {this.renderCheckOut()}
                                {this.renderPayment()}
                            </Col>
                        </Row>

                    </div>
                </AvForm>
                {this.state.loading && <Loading/>}
            </div>);
        }
    }

    renderNewBillingAddress() {
        let styles = {
            row: {
                flex: 1,
                display: "flex",
                flexDirection: "row"
            }
        }
        return (<div>

            <Row>

            </Row>


            <Row>
                <div style={styles.row}>


                    <Col md="8">
                        <AvGroup>
                            <Label for="email">
                                <Translate contentKey="merchantBranch.tradingName">Post Code
                                </Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>

                            <Dropdown value={this.state.memberBillingAddress.postCode}
                                      name="postCode"
                                      style={{display: "content !important"}}
                                      ref={elem => this.dropDownBillingAddress = elem}
                                      className="form-control"
                                      disabled={false}
                                      options={this.state.filteredBillingAddresses}
                                      onChange={this.__handleChangeBillingAddress} optionLabel="displayString"
                                      editable placeholder=""/>


                        </AvGroup>


                    </Col>
                    <Col md="4">

                        <span className="pi pi-search" disabled={false}
                              style={{fontSize: "1.75rem", position: "relative", left: "-20px", top: "10px"}}
                              onClick={(e) => {
                                  this.searchBillingAddress(e);
                              }}></span>

                    </Col>
                </div>
            </Row>


            <Row>
                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">City
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="city"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 100 characters in this field."
                                }
                            }}
                            value={this.state.memberBillingAddress.city}
                            onChange={this.__handleChangeBillingAddress}/>
                    </AvGroup>
                </Col>


                <Col md="6">
                </Col>
            </Row>

            <Row>
                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Address Line 1
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="addressLine1"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 50,
                                    errorMessage: "You can enter up to 50 characters in this field."
                                }
                            }}
                            value={this.state.memberBillingAddress.addressLine1}
                            onChange={this.__handleChangeBillingAddress}/>
                    </AvGroup>
                </Col>
                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Address Line 2
                            </Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="addressLine2"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 100 characters in this field."
                                }
                            }}
                            value={this.state.memberBillingAddress.addressLine2}
                            onChange={this.__handleChangeBillingAddress}/>
                    </AvGroup>
                </Col>
            </Row>

            <Row>
                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Address Line 3
                            </Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="addressLine3"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 100 characters in this field."
                                }
                            }}
                            value={this.state.memberBillingAddress.addressLine3}
                            onChange={this.__handleChangeBillingAddress}/>
                    </AvGroup>
                </Col>
                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Address Line 4
                            </Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="addressLine4"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 100 characters in this field."
                                }
                            }}
                            value={this.state.memberBillingAddress.addressLine4}
                            onChange={this.__handleChangeBillingAddress}/>
                    </AvGroup>
                </Col>
            </Row>

        </div>)

    }

    renderNewAddress() {

        let styles = {
            row: {
                flex: 1,
                display: "flex",
                flexDirection: "row"
            }
        }


        return (<div>

            <Row>

            </Row>


            <Row>
                <div style={styles.row}>


                    <Col md="8">
                        <AvGroup>

                            <Label for="email">
                                <Translate contentKey="merchantBranch.tradingName">Post Code
                                </Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>


                            <Dropdown ref={elem => this.dropDownAddress = elem}
                                      value={this.state.memberUserAddress.postCode}
                                      name="postCode"
                                      showOnFocus={false}
                                      className="form-control"
                                      disabled={false}
                                      options={this.state.filteredAddresses}
                                      onChange={this.__handleChangeAddress} optionLabel="displayString"
                                      editable placeholder=""/>


                        </AvGroup>


                    </Col>

                    <Col md="4">
                        <span className="pi pi-search" disabled={false}
                              style={{fontSize: "1.75rem", position: "relative", left: "-20px", top: "10px"}}
                              onClick={(e) => {
                                  this.searchAddress(e);
                              }}></span>

                    </Col>
                </div>

            </Row>

            <Row>
                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">City
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="city"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 100 characters in this field."
                                }
                            }}
                            value={this.state.memberUserAddress.city}
                            onChange={this.__handleChangeAddress}/>
                    </AvGroup>
                </Col>


                <Col md="6">
                </Col>
            </Row>

            <Row>
                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Address Line 1
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="addressLine1"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 50,
                                    errorMessage: "You can enter up to 50 characters in this field."
                                }
                            }}
                            value={this.state.memberUserAddress.addressLine1}
                            onChange={this.__handleChangeAddress}/>
                    </AvGroup>
                </Col>
                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Address Line 2
                            </Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="addressLine2"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 100 characters in this field."
                                }
                            }}
                            value={this.state.memberUserAddress.addressLine2}
                            onChange={this.__handleChangeAddress}/>
                    </AvGroup>
                </Col>
            </Row>

            <Row>
                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Address Line 3
                            </Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="addressLine3"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 100 characters in this field."
                                }
                            }}
                            value={this.state.memberUserAddress.addressLine3}
                            onChange={this.__handleChangeAddress}/>
                    </AvGroup>
                </Col>
                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Address Line 4
                            </Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="addressLine4"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 100 characters in this field."
                                }
                            }}
                            value={this.state.memberUserAddress.addressLine4}
                            onChange={this.__handleChangeAddress}/>
                    </AvGroup>
                </Col>
            </Row>

        </div>)
    }

    createAccessTokenForDojo(crossReference, paymentType) {
        var address = this.state.sameAsDeliveryAddress == true ? this.state.memberUserAddress : Number.isInteger(this.state.paymentOption) || this.state.paymentOption == "CARD" ? this.state.memberBillingAddress : null;

        this.paymentService.createToken(localStorage.getItem("merchantBranchId"), crossReference, address).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({accessToken: response.id, crossReference: crossReference, paymentType: paymentType});
                this.renderDOJOFrame(crossReference, paymentType);
            } else {
                this.reloadPage("")
                this.setState({
                    loading: false,
                    confirmButtonDisabled: false,
                    confirmButtonName: 'Confirm Order'
                });
            }
        });
    }

    async getMerchantSessionKeyForOpayo() {
        const response = (await this.paymentService.createToken(localStorage.getItem("merchantBranchId"), null, null));
        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
            this.setState({
                accessToken: response.merchantSessionKey
            });
        }

    }

    async createAccessTokenForOpayo(crossReference) {
        const response = (await this.paymentService.createToken(localStorage.getItem("merchantBranchId"), null, null));
        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
            this.renderOpayoFrame(response.merchantSessionKey, crossReference);
        } else {
            this.reloadPage("")
            this.setState({
                loading: false,
                confirmButtonDisabled: false,
                confirmButtonName: 'Confirm Order'
            });
        }

    }

    getPaymentOption(value) {
        this.setState({
            paymentOption: value,
            crossReference: null
        });

        this.removeCardIframes();

        if ((value == "CARD" || value == "WALLET") && this.state.order.provider === "DOJO") {
            this.createAccessTokenForDojo(null, value)
        } else if ((value == "CARD" || value == "WALLET") && this.state.order.provider === "OPAYO") {
            this.createAccessTokenForOpayo(null)
        }
        else if (value == "APPLE PAY" && this.state.order.provider === "OPAYO") {
            this.getMerchantSessionKeyForOpayo();
        }
    }

    paymentItem(item) {
        return (<div>
            <div className="field-radiobutton">

                <RadioButton inputId={item.id} name={item.name} value={item.id}
                             onChange={(e) => {
                                 this.getPaymentOption(e.value)

                             }}
                             checked={this.state.paymentOption === item.id}/>
                <label htmlFor={item.id}>&nbsp;&nbsp;{item.name}&nbsp;&nbsp;</label>


            </div>
        </div>);
    }

    stopLoading() {
        this.setState({loading: false});
    }

    displayErrors = (errors) => {
        if (errors && errors.length) {
            for (var i = 0; i < errors.length; i++) {
                if (!GeneralUtils.isNullOrEmpty(errors[i].message)) {
                    toast.warn(errors[i].message);
                } else {
                    toast.warn(errors[i].errorType);
                }
            }
        } else {
            toast.warn("An unexpected error has occurred.");
        }
        this.setState({
            loading: false,
            confirmButtonDisabled: false,
            confirmButtonName: 'Confirm Order'
        });
    }


    onPaymentCancelled = () => {
        this.setState({
            loading: false,
        });
    }


    onPaymentInitiated = () => {
        this.setState({
            loading: true,
        });
    }

    paymentComplete = (response) => {
        if (response.statusCode == 0) {
            this.confirmOrder();
        } else {
            this.setState({
                loading: false,
                confirmButtonDisabled: false,
                confirmButtonName: 'Confirm Order'
            });
            if (!GeneralUtils.isNullOrEmpty(response.message)) {
                toast.warn(response.message);
            } else {
                toast.warn("An unexpected error has occurred.");
            }
        }
    }


    renderDOJOFrame(crossReference, paymentType) {

        const displayErrors = "";

        if (GeneralUtils.isNullOrEmpty(crossReference)) {

            if (paymentType == "CARD") {
                const payConfig = {
                    onIframeLoaded: () => {
                        this.stopLoading()
                    },
                    paymentDetails: {
                        paymentToken: this.state.accessToken
                    },
                    containerId: "card-payment",
                    fontCss: ['https://fonts.googleapis.com/css?family=Do+Hyeon'],
                    styles: {
                        base: {
                            default: {
                                color: "black",
                                textDecoration: "none",
                                fontFamily: "'Do Hyeon', sans-serif",
                                boxSizing: "border-box",
                                padding: ".375rem .75rem",
                                boxShadow: 'none',
                                fontSize: '1rem',
                                borderRadius: '.25rem',
                                lineHeight: '1.5',
                                backgroundColor: '#fff',

                            }, focus: {
                                color: '#495057', borderColor: '#80bdff',
                            }, error: {
                                color: "#B00", borderColor: "#B00"
                            }, valid: {
                                color: "green", borderColor: 'green'
                            }, label: {
                                display: 'none'
                            }
                        }, cv2: {
                            container: {
                                width: "50%", float: "left", boxSizing: "border-box"
                            }, default: {
                                borderRadius: "0 .25rem .25rem 0"
                            }
                        }, expiryDate: {
                            container: {
                                width: "50%", float: "left", borderRadius: '0rem',
                            }, default: {
                                borderRadius: "0", borderRight: "none"
                            },
                        }, cardNumber: {
                            container: {
                                width: "100%", float: "left",
                            }, default: {
                                borderRadius: ".25rem 0 0 .25rem"
                            },
                        }
                    }
                }
                const connectE1 = new window.Connect.ConnectE(payConfig, displayErrors);
                this.setState({connectE: connectE1});
            } else {
                const walletPaymentCallbacksConfig = {
                    onPaymentCancelled: () => {
                        this.onPaymentCancelled()
                    },
                    onPaymentInitiated: () => {
                        this.onPaymentInitiated()
                    },
                }
                const payConfig = {
                    onIframeLoaded: () => {
                        this.stopLoading()
                    },
                    containerId: 'payment-wallet',
                    paymentDetails: {
                        paymentToken: this.state.accessToken
                    },
                    buttonConfig: {
                        colour: 'white',
                        color: 'white',
                        type: 'order'
                    },
                    emailRequired: false,
                    billingAddressRequired: false,
                    shippingAddressRequired: false,
                    callbacks: walletPaymentCallbacksConfig
                }

                const connectE1 = new window.Connect.Wallet(payConfig, this.displayErrors, this.paymentComplete);
                this.setState({connectE: connectE1, that: this});
            }

        } else {
            const payConfig = {
                onIframeLoaded: () => {
                    this.stopLoading()
                }, paymentDetails: {
                    paymentToken: this.state.accessToken
                }, containerId: "cvv-payment", fontCss: ['https://fonts.googleapis.com/css?family=Do+Hyeon'], styles: {
                    cv2: {
                        default: {
                            color: "black",
                            textDecoration: "none",
                            fontFamily: "'Do Hyeon', sans-serif",
                            boxSizing: "border-box",
                            padding: ".375rem .75rem",
                            boxShadow: 'none',
                            fontSize: '1rem',
                            borderRadius: '.25rem',
                            lineHeight: '1.5',
                            backgroundColor: '#fff'
                        }, focus: {
                            color: '#495057',
                            backgroundColor: '#fff',
                            borderColor: '#80bdff',
                            outline: '0',
                            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
                        }, error: {
                            color: "#B00", borderColor: "#B00"
                        }, valid: {
                            color: "green"
                        }, label: {
                            display: 'none'
                        }, container: {
                            width: "50%", float: "left", paddingLeft: "5px", boxSizing: "border-box"
                        },
                    },

                }, text: {
                    cv2: {
                        label: "CVV", placeholder: "CVV"
                    }
                }
            };
            const connectE1 = new window.Connect.ConnectERecurringPayment(payConfig, displayErrors);
            this.setState({connectE: connectE1});
        }
    }

    renderPaymentMethods() {
        let form = [];

        this.state.paymentSettings.forEach(itemSub => {
            form.push(this.paymentItem(itemSub));
            form.push(<p></p>);
            if (itemSub.name == "CARD") {
                form.push(this.state.paymentOption == "CARD" && <div id="card-payment">
                </div>);

            }

            if (itemSub.name == "WALLET") {
                form.push(<div id="payment-wallet">
                </div>);
            }
        });

        return form;
    }

    setChecked = (name, value) => {
        let order = this.state.order;
        order[name] = value;
        this.setState({order: order});
    }

    setCheckedAddress = (name, value) => {
        let memberUserAddress = this.state.memberUserAddress;
        memberUserAddress[name] = value;
        this.setState({memberUserAddress: memberUserAddress});
    }

    setCheckedBillingAddress = (name, value) => {
        let memberBillingAddress = this.state.memberBillingAddress;
        memberBillingAddress[name] = value;
        this.setState({memberBillingAddress: memberBillingAddress});
    }

    setSameDeliveryAddress = (name, value) => {
        this.setState({sameAsDeliveryAddress: value});
        if (value) {
            let memberBillingAddress = this.state.memberBillingAddress;
            memberBillingAddress["saveBillingAddress"] = false;
            this.setState({memberBillingAddress: memberBillingAddress});
        }
    }

    removeCardIframes() {
        var frame = document.getElementById("iframe-card-payment");
        if (frame != null) {
            frame.parentNode.removeChild(frame);
        }

        var frame = document.getElementById("iframe-cvv-payment");
        if (frame != null) {
            frame.parentNode.removeChild(frame);
        }

        var frame = document.getElementById("iframe-payment-wallet");
        if (frame != null) {
            frame.parentNode.removeChild(frame);
        }


    }

    renderOrderTime(title) {
        let form = [];

        form.push(<Row>
            <Col md="12">
                <Label for="firstName">
                    <Translate contentKey="saveAllergies">&nbsp;&nbsp;{title}</Translate>

                </Label>
                <AvGroup>
                    <AvField
                        type="select"
                        name="orderTimeForDelivery"
                        disabled={false}
                        value={this.state.order.orderTimeForDelivery}
                        onChange={this.__handleChangeOrder}>
                        {this.state.order.orderTimeForDeliveryList.map(element => (
                            <option value={element.id} key={element.name}>
                                {element.name}
                            </option>))}
                    </AvField>
                </AvGroup>


            </Col>


        </Row>)

        return form;
    }

    onChangeDeliveryType(value) {
        let order = this.state.order;
        order["orderType"] = value;
        order["saveCard"] = false;
        this.setState({
            order: order,
            paymentOption: this.state.order.provider == "OPAYO" ? this.state.order.paymentOption : null,
        });
        if (this.refOrderCheckout != null) {
            this.refOrderCheckout.setBranchSettings(value, this.state.minimumDeliveryBasketPrice, this.state.isClosed, this.state.serviceChargeOptions);
        }
        if (this.state.order.orderType == "Delivery") {
            this.setState({
                sameAsDeliveryAddress: true
            });
        } else {
            this.setState({
                sameAsDeliveryAddress: false
            });
        }


        this.getPaymentSettings();
        this.getBasketInfo(value);
        this.removeCardIframes();
    }

    showDeliveryType(type) {
        var showDeliveryType = false;
        for (var i = 0; i < this.state.deliveryTypesList.length; i++) {
            if (this.state.deliveryTypesList[i].name == type) {
                showDeliveryType = true;
            }
        }

        return showDeliveryType
    }

    renderDeliveryTypes() {
        let form = [];
        form.push(<ButtonGroup style={{display: "flex"}}>
            {this.showDeliveryType("Delivery") &&
                <Button
                    color="primary"
                    outline
                    onClick={(e) => {
                        this.onChangeDeliveryType("Delivery")
                    }}
                    active={this.state.order.orderType === "Delivery"}
                >
                    Delivery
                </Button>
            }
            {this.showDeliveryType("Collection") &&
                <Button
                    color="primary"
                    outline
                    onClick={(e) => {
                        this.onChangeDeliveryType("Collection")
                    }}
                    active={this.state.order.orderType === "Collection"}
                >
                    Collection
                </Button>
            }
            {this.showDeliveryType("Table Order") &&
                <Button
                    color="primary"
                    outline
                    onClick={(e) => {
                        this.onChangeDeliveryType("Table Order")
                    }}
                    active={this.state.order.orderType === "Table Order"}
                >
                    Table Order
                </Button>}


        </ButtonGroup>);


        return form;
    }

    renderDelivery() {
        let form = [];

        form.push(<div>
            {this.renderDeliveryTypes()}
            {<p></p>}
        </div>);

        if (this.state.order.orderType == "Collection") {
            form.push(<div>
                {this.renderOrderTime("Ready By")}
            </div>);
        }
        if ((GeneralUtils.isNullOrEmpty(this.state.order.scheduledForDelivery) || this.state.order.scheduledForDelivery == "true") && this.state.order.orderType == "Delivery") {
            form.push(<div>
                {this.renderOrderTime("Deliver By")}
            </div>);
        }


        form.push(<div>
            <Fieldset legend={"User"} toggleable>
                {this.renderUserInfo()}
            </Fieldset>
        </div>);


        if (this.state.order.orderType == "Table Order") {

            form.push(<div>
                <Fieldset legend={"Address"} toggleable>
                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="tableNo">
                                    <Translate contentKey="contactManagement.mobilePhone">&nbsp;&nbsp;Table
                                        No</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    name="tableNo"
                                    disabled={false}
                                    value={this.state.order.tableNo}
                                    onChange={this.__handleChangeOrder}>
                                    <option value='' key=''/>
                                    {this.state.tables.map(element => (
                                        <option value={element.tableNo} key={element.tableNo}>
                                            {element.tableNo}
                                        </option>))}
                                </AvField>
                            </AvGroup>


                        </Col>


                    </Row>


                </Fieldset>

            </div>)
        } else {

            if (this.state.order.orderType == "Delivery") {
                form.push(<div>
                    <Fieldset legend={"Delivery Address"} toggleable>
                        {this.addressListItem()}
                    </Fieldset>
                </div>);
            }
        }

        if (this.state.paymentOption == "CARD" || this.state.paymentOption == "APPLE PAY" || this.state.paymentOption == "PAY PAL") {
            form.push(<div>
                <Fieldset legend={"Billing Address"} toggleable>
                    {this.billingAddressListItem()}
                </Fieldset>
            </div>);
        }

        form.push(this.renderAllergiesAndNotes());
        return form;
    }

    renderAllergiesAndNotes() {
        let form = [];
        form.push(<div>
            <Fieldset legend={"Allergies & Notes"} toggleable collapsed={true}>
                <InputTextarea value={this.state.order.orderNotes}
                               style={{borderRadius: "5px", width: "-webkit-fill-available"}}
                               name="orderNotes"
                               placeholder="Leave a note for the store with anything they need to know"
                               disabled={this.state.viewMode}
                               rows={2} cols={50}
                               maxLength={100}
                               onChange={this.__handleChangeOrder}/>
                <p></p>

                <InputTextarea value={this.state.order.allergies}
                               style={{borderRadius: "5px", width: "-webkit-fill-available"}}
                               name="allergies"
                               placeholder="Allergies"
                               disabled={this.state.viewMode}
                               rows={2} cols={50}
                               maxLength={100}
                               onChange={this.__handleChangeOrder}/>
                <p></p>
            </Fieldset>
        </div>);
        return form;

    }

    billingAddressListItem() {
        let form = [];

        form.push(<Row>
            {this.state.order.orderType == "Delivery" &&
                <Col md="12">
                    <AvGroup>
                        <Checkbox onChange={(e) => this.setSameDeliveryAddress("sameAsDeliveryAddress", e.checked)}
                                  name="sameAsDeliveryAddress"
                                  value={this.state.sameAsDeliveryAddress}
                                  checked={this.state.sameAsDeliveryAddress}></Checkbox>

                        <Label for="firstName">
                            <Translate contentKey="sameAsDeliveryAddress">&nbsp;&nbsp;Same as my delivery
                                address</Translate>

                        </Label>
                    </AvGroup>
                </Col>
            }


        </Row>);

        if (this.state.sameAsDeliveryAddress == false) {
            form.push(this.renderNewBillingAddress());
        }

        return form;
    }

    renderUserInfo() {
        let form = [];
        form.push(<div><Row>
            <Col md="6">
                <AvGroup>
                    <Label for="email">
                        <Translate contentKey="merchantBranch.tradingName">First Name
                        </Translate>
                        <span style={{color: 'red'}}> (*)</span>
                    </Label>
                    <AvField
                        type="text"
                        className="form-control"
                        name="firstName"
                        disabled={false}
                        validate={{
                            maxLength: {
                                value: 20,
                                errorMessage: "You can enter up to 255 characters in this field."
                            }
                        }}
                        value={this.state.memberUserAddress.firstName}
                        onChange={this.__handleChangeAddress}/>
                </AvGroup>
            </Col>

            <Col md="6">
                <AvGroup>
                    <Label for="email">
                        <Translate contentKey="merchantBranch.tradingName">Last Name
                        </Translate>
                        <span style={{color: 'red'}}> (*)</span>
                    </Label>
                    <AvField
                        type="text"
                        className="form-control"
                        name="lastName"
                        disabled={false}
                        validate={{
                            maxLength: {
                                value: 20,
                                errorMessage: "You can enter up to 255 characters in this field."
                            }
                        }}
                        value={this.state.memberUserAddress.lastName}
                        onChange={this.__handleChangeAddress}/>
                </AvGroup>
            </Col>

        </Row>

            <Row>

                <Col md="6">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.emailAddress">Email
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="email"
                            className="form-control"
                            name="email"
                            disabled={false}
                            validate={{
                                minLength: {
                                    "value": 5, "errorMessage": "Not Valid Email"
                                }, maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 50 characters in this field."
                                }
                            }}
                            value={this.state.memberUserAddress.email}
                            onChange={this.__handleChangeAddress}/>
                    </AvGroup>
                </Col>
                <Col md="6">
                    <AvGroup>
                        <Label for="mobilePhone">
                            <Translate contentKey="contactManagement.mobilePhone">Mobile
                                Phone</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>

                        <InputMask id="mobilePhone"
                                   className="form-control"
                                   disabled={false}
                                   value={this.state.memberUserAddress.mobilePhone}
                                   onChange={this.__handleChangeAddress}
                                   name="mobilePhone"
                                   unmask={true}
                                   mask="(99999) 999999"
                                   placeholder="(07999) 999999"></InputMask>

                    </AvGroup>
                </Col>
            </Row></div>)
        return form;
    }

    addressListItem() {
        let form = [];

        form.push(this.renderNewAddress())

        return form;
    }

    async reloadPage(message) {
        if (!GeneralUtils.isNullOrEmpty(message)) {
            localStorage.removeItem(localStorage.getItem("merchantBranchId"));
            localStorage.removeItem("orderType");
            localStorage.removeItem("postCode");
            localStorage.removeItem("description");
            localStorage.removeItem("addressLine1");
            localStorage.removeItem("addressLine2");
            localStorage.removeItem("addressLine3");
            localStorage.removeItem("addressLine4");
            localStorage.removeItem("city");
        }
        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("merchantBranchId")) && localStorage.getItem("merchantBranchId") != -1) {
            const response = (await this.merchantBranchService.getStoreInfo(localStorage.getItem("merchantBranchId")));
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type) && !GeneralUtils.isNullOrEmpty(response.domainName)) {
                if (GeneralUtils.isServedInWebMarketUrl()) {
                    this.setState({
                        loading: false,
                    });

                    window.location.href = response.domainName + message;
                } else {
                    this.setState({
                        loading: false,
                    });
                    window.location.href = window.location.href.replace("/approveOrder","").replace("/confirmOrder","")  + message;
                }

            } else {
                this.setState({
                    loading: false,
                });
                window.location.href = message;
            }
        } else {
            this.setState({
                loading: false,
            });
            window.location.href = message;
        }
    }

    checkValidations() {
        let orderData = this.refOrderCheckout.getData();

        if (orderData.checkOutItemList.length == 0) {
            return "Your order is empty!";
        }
        if (GeneralUtils.isNullOrEmpty(this.state.order.orderType)) {
            return "Please select a delivery type!";
        }
        if (GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.firstName) || GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.lastName) || GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.email) || GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.mobilePhone)) {
            return "Please fill the user details";
        }
        if(!GeneralUtils.validateEmail(this.state.memberUserAddress.email)){
            return "Not valid email!";
        }
        if (this.state.order.orderType == "Delivery") {
            if (GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.postCode) || GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.addressLine1) || GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.city)) {
                return "Please fill the address details";
            }
            if (!GeneralUtils.isNullOrEmpty(this.state.minimumDeliveryBasketPrice) && this.refOrderCheckout.getData().totalPrice < this.state.minimumDeliveryBasketPrice) {
                return "Your basket total is under minimum basket total";
            }
        }

        if (this.state.order.orderType == "Table Order" && GeneralUtils.isNullOrEmpty(this.state.order.tableNo)) {
            return "Please select Table number";
        }
        if (GeneralUtils.isNullOrEmpty(this.state.paymentOption)) {
            return "Please select payment type";
        }

        if (this.state.sameAsDeliveryAddress == false && (Number.isInteger(this.state.paymentOption) || this.state.paymentOption == "CARD" || this.state.paymentOption == "APPLE PAY" || this.state.paymentOption == "PAY PAL") && (this.state.order.provider == "DOJO" || this.state.order.provider == "OPAYO")) {
            if (GeneralUtils.isNullOrEmpty(this.state.memberBillingAddress.postCode) || GeneralUtils.isNullOrEmpty(this.state.memberBillingAddress.addressLine1) || GeneralUtils.isNullOrEmpty(this.state.memberBillingAddress.city)) {
                return "Please fill the billing address details";
            }
        }
    }

    confirmOrder = (session, applePayment) => {
        if (this.refOrderCheckout != null) {
            var validations = this.checkValidations();
            if (GeneralUtils.isNullOrEmpty(validations)) {
                let orderData = this.refOrderCheckout.getData();
                let data = {
                    orderNotes: this.state.order.orderNotes,
                    allergies: this.state.order.allergies,
                    basketDetailList: orderData.checkOutItemList,
                    orderType: this.state.order.orderType,
                    orderTimeForDelivery: this.state.order.orderTimeForDelivery,
                    tableNo: this.state.order.orderType == "Table Order" ? this.state.order.tableNo : null,
                    memberUserAddress: this.state.memberUserAddress,
                    memberBillingAddress: this.state.sameAsDeliveryAddress == true ? this.state.memberUserAddress : this.state.memberBillingAddress,
                    id: orderData.id,
                    saveCard: false,
                    paymentAccessToken: this.state.accessToken,
                    cardIdentifier: this.state.cardIdentifier,
                    paymentType: Number.isInteger(this.state.paymentOption) ? "CARD" : this.state.paymentOption,
                    cardNumber: Number.isInteger(this.state.paymentOption) ? this.state.paymentOption : null,
                    website: GeneralUtils.getUiUrl(),
                    notificationURL: SERVER_URL,
                    applePayPayment: applePayment

                };

                this.setState({loading: true, confirmButtonDisabled: true, confirmButtonName: 'Please Wait'});

                this.basketService.placeOrder(data).then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        if (!GeneralUtils.isNullOrEmpty(response.acsUrl)) {
                            this.opoyaThreeDSecurePostPage(response);
                        } else if (!GeneralUtils.isNullOrEmpty(response.redirectUrl)) {
                            window.location.href = response.redirectUrl;
                        } else {
                            this.setState({
                                confirmButtonDisabled: false,
                                confirmButtonName: 'Confirm Order'
                            });

                            if (this.state.order.provider == "OPAYO" && this.state.paymentOption == "APPLE PAY") {
                                if (!GeneralUtils.isNullOrEmpty(session)) {
                                    session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
                                }
                            }


                            this.reloadPage("?successfulMessage=true")


                        }

                    } else {
                        if (response.type) {
                            if (response.message == "Your order is empty!") {
                                this.getBasketInfo(this.state.order.orderType);
                            }
                            GeneralUtils.showNotification(response);
                        }
                        this.setState({
                            loading: false,
                            paymentOption: null,
                            confirmButtonDisabled: false,
                            confirmButtonName: 'Confirm Order'
                        });
                    }
                });


                localStorage.removeItem("tableNo");
            } else {
                return toast.warn(validations);
            }
        }


    }

    opoyaThreeDSecurePostPage(response) {
        const form = document.createElement('form');
        form.method = "post";
        form.action = response.acsUrl;
        const hiddenField = document.createElement('input');
        hiddenField.type = "hidden";
        hiddenField.name = "creq";
        hiddenField.value = response.creq;
        form.appendChild(hiddenField);
        const hiddenField1 = document.createElement('input');
        hiddenField1.type = "hidden";
        hiddenField1.name = "threeDSSessionData";
        hiddenField1.value = response.transactionId;
        form.appendChild(hiddenField1);
        document.body.appendChild(form);
        form.submit();
    }

    renderOpayoFrame(merchantSessionKey, crossReference) {
        if (this.state.connectE != undefined && this.state.connectE != null) {
            this.state.connectE.destroy();
        }

        if (GeneralUtils.isNullOrEmpty(crossReference)) {
            this.setState({
                cardIdentifier: null,
                accessToken: merchantSessionKey,
                connectE: new window.sagepayCheckout({
                    containerSelector: '#card-payment',
                    merchantSessionKey: merchantSessionKey,
                    onTokenise: this.onTokeniseOpayo
                })
            });
        } else {
            this.setState({
                cardIdentifier: crossReference,
                accessToken: merchantSessionKey,
                connectE: new window.sagepayCheckout({
                    containerSelector: '#cvv-payment',
                    reusableCardIdentifier: crossReference,
                    merchantSessionKey: merchantSessionKey,
                    onTokenise: this.onTokeniseOpayo
                })
            });
        }

    }

    setCardIdentifier = (cardIdentifier) => {
        if (!GeneralUtils.isNullOrEmpty(cardIdentifier)) {
            this.setState({
                cardIdentifier: cardIdentifier,
            });
        }

        this.confirmOrder();
    }

    onTokeniseOpayo = (response) => {
        if (response.success) {
            this.setCardIdentifier(response.cardIdentifier);
        }
    }

    getOpayoPayment() {
        this.state.connectE.tokenise();
    }

    getDOJOPayment() {
        this.setState({
            confirmButtonDisabled: true, confirmButtonName: 'Please Wait'
        });
        var extraInfo = {
            savePaymentMethod: this.state.order.saveCard
        };

        let that = this;
        this.state.connectE.executePayment(extraInfo).then(function (data) {
            if (data.statusCode == 99) {
                that.confirmOrder()
            } else {
                that.setState({
                    loading: false,
                    confirmButtonDisabled: false,
                    confirmButtonName: 'Confirm Order'
                });
                if (!GeneralUtils.isNullOrEmpty(data.message)) {
                    toast.warn(data.message);
                } else if (data.statusCode == 5) {
                    that.removeCardIframes();
                    that.createAccessTokenForDojo(that.state.crossReference, that.state.paymentType);
                } else {
                    toast.warn("An unexpected error has occurred.");
                }

            }


        }).catch(function (data) {
            that.setState({
                loading: false,
                confirmButtonDisabled: false,
                confirmButtonName: 'Confirm Order'
            });

            if (data.statusCode == 401) {
                window.location.reload();

            } else {
                if (!GeneralUtils.isNullOrEmpty(data.message)) {
                    toast.warn(data.message);
                }
            }
        });
    }

    onClickConfirmOrderButton() {
        var validations = this.checkValidations();
        if (GeneralUtils.isNullOrEmpty(validations)) {
            if (this.state.order.provider == "DOJO" && (this.state.paymentOption == "CARD" || this.state.paymentOption == "WALLET" || Number.isInteger(this.state.paymentOption))) {
                this.getDOJOPayment();
            } else if (this.state.order.provider == "OPAYO" && (this.state.paymentOption == "CARD" || Number.isInteger(this.state.paymentOption))) {
                this.getOpayoPayment();
            } else if (this.state.order.provider == "OPAYO" && this.state.paymentOption == "APPLE PAY") {
                if (this.refOrderCheckout != null) {
                    var validations = this.checkValidations();
                    if (!GeneralUtils.isNullOrEmpty(validations)) {
                        return toast.warn(validations);
                    } else {
                        this.tokeniseApplePay();
                    }
                }


            } else {
                this.confirmOrder();
            }
        } else {
            return toast.warn(validations);
        }
    }

    renderPayment() {

        if (this.state.paymentOption == "APPLE PAY") {
            document.getElementById('apple-pay-button').style.display = "block";
            document.getElementById('apple-pay-button').style.width = "100%"
            document.getElementById('apple-pay-button').style.marginBottom = "5px";
            document.getElementById('apple-pay-button').style.borderRadius = "5px";
            document.getElementById('apple-pay-button').style.padding = "5px 0px";

        } else {
            let applePayButton = document.getElementById('apple-pay-button');
            if (applePayButton != null) {
                applePayButton.style.display = "none";
            }

        }

        let marginTop ="0px";

        if(GeneralUtils.isMobile()){
            marginTop ="10px";
        }
        let disableCancelButton = true;
        if(this.refOrderCheckout != undefined && this.refOrderCheckout.getData().checkOutItemList.length>0){
            disableCancelButton = false;
        }

        return (<div>
            <Fieldset legend={"Payment"} toggleable>

                {this.renderPaymentMethods()}

                <Row>
                    <Col md="10">

                        <apple-pay-button id="apple-pay-button" style={{display: "none"}}
                                          buttonstyle="white-outline" type="check-out"
                                          locale="en-GB"></apple-pay-button>


                        {this.state.paymentOption != "WALLET" && this.state.paymentOption != "APPLE PAY" &&

                            <Button color="outline-primary" disabled={this.state.confirmButtonDisabled}
                                    style={{width: '100%', marginBottom: '5px'}}
                                    onClick={() => {

                                        this.onClickConfirmOrderButton()

                                    }}>
                                <Translate contentKey="entity.action.add">{this.state.confirmButtonName}</Translate>
                            </Button>
                        }

                    </Col>
                </Row>

                {!GeneralUtils.isMobile() &&
                    <Row>
                        <Col md={10}>
                            <hr></hr>
                        </Col>

                    </Row>
                }


                <Row>


                    <Col md="5">
                        <Button color="outline-danger" disabled={false}
                                style={{width: '100%', marginTop:marginTop}}
                                onClick={() => {

                                    this.setState({loading: true});
                                    this.basketService.cancelOrder(localStorage.getItem("merchantBranchId")).then((response) => {
                                        this.reloadPage("?cancelledMessage=true")
                                        this.setState({loading: false});
                                    });


                                }}>
                            <Translate contentKey="entity.action.add">Cancel Order</Translate>
                        </Button>
                    </Col>

                    <Col md="5">
                        <Button color="outline-primary" disabled={false}
                                style={{width: '100%', marginTop:marginTop}}
                                onClick={() => {
                                    this.reloadPage("")

                                }}>
                            <Translate contentKey="entity.action.add">Continue Shopping</Translate>
                        </Button>
                    </Col>

                </Row>

                {
                    <Row>




                    </Row>

                }

            </Fieldset>
        </div>);
    }

    tokeniseApplePay() {
        if (window.ApplePaySession) {
            const payreq = {
                countryCode: 'GB',
                currencyCode: 'GBP',
                total: {
                    label: 'Total Cost',
                    amount: this.refOrderCheckout.getData().totalPrice.toFixed(2),
                },
                supportedNetworks: ['amex', 'masterCard', 'visa'],
                merchantCapabilities: ['supports3DS'],
            };
            const appleSession = window.ApplePaySession;
            const session = new appleSession(6, payreq)
            let that = this;
            session.onvalidatemerchant = async function (event) {
                try {
                    const response = (await that.paymentService.createApplePayToken(localStorage.getItem("merchantBranchId")));
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        session.completeMerchantValidation(response)
                    } else {
                        console.log(response)
                    }

                } catch (error) {
                    console.log(error)
                }

            }
            session.onpaymentauthorized = async function (event) {
                that.confirmOrder(session, event.payment);
            }
            session.oncancel = function (event) {
                console.log(window.ApplePaySession.STATUS_SUCCESS);
            };
            session.begin()
        }
    }

    renderCheckOut() {
        return <OrderCheckOut ref={(elem) => this.refOrderCheckout = elem}
                              refreshBasket={this.refreshBasket}
                              createDojoTokenAgain={this.createDojoTokenAgain}
                              openItemDetails={this.openItemDetails}></OrderCheckOut>;

    }

    refreshBasket = () => {
        this.getBasketInfo(this.state.order.orderType);
    }

    getBasketInfo = async (orderType) => {
        if ((GeneralUtils.hasWebOrderLicense() || GeneralUtils.hasTableOrderLicense())) {

            const response = (await this.basketService.findBasketCreatedByMerchantBranchId(localStorage.getItem("merchantBranchId"), orderType));
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("tableNo"))) {
                    let order = this.state.order;
                    order["tableNo"] = localStorage.getItem("tableNo");
                    this.setState({order: order});
                } else {
                    let order = this.state.order;
                    order["tableNo"] = response.tableNo;
                    this.setState({order: order});
                }
                if (this.refOrderCheckout != null) {
                    this.refOrderCheckout.setPageMode(PAGE_MODE.VIEW);
                    this.refOrderCheckout.setData(response);
                }

            }
        }
    }

    __handleChangeOrder = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let order = this.state.order;
        order[e.target.name] = value;
        this.setState({order: order});
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let memberUserCard = this.state.memberUserCard;
        memberUserCard[e.target.name] = value;
        this.setState({memberUserCard: memberUserCard});
    }

    __handleChangeAddress = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let memberUserAddress = this.state.memberUserAddress;
        memberUserAddress[e.target.name] = value;
        if (!GeneralUtils.isNullOrEmpty(value) && value.postCode !== undefined) {
            memberUserAddress = this.getAddressDetailFromFilteredCombo(value)
        }
        this.setState({memberUserAddress: memberUserAddress});


    }

    __handleChangeBillingAddress = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let memberBillingAddress = this.state.memberBillingAddress;
        memberBillingAddress[e.target.name] = value;
        if (!GeneralUtils.isNullOrEmpty(value) && value.postCode !== undefined) {
            memberBillingAddress = this.getAddressDetailFromFilteredCombo(value)
        }
        this.setState({memberBillingAddress: memberBillingAddress});
    }

    searchAddress = (e) => {

        if (this.state.memberUserAddress.postCode != null && this.state.memberUserAddress.postCode.length >= 6) {
            this.addressService.getAddresses(localStorage.getItem("merchantBranchId"), this.state.memberUserAddress.postCode).then(response => {
                this.setState({filteredAddresses: response});
                if (response.length > 0) {
                    e.which = 99;
                    this.dropDownAddress.onInputKeyDown(e);
                } else {
                    return toast.info("No Available Address");
                }


            });

        } else {
            this.setState({filteredAddresses: []});
        }

    }

    searchBillingAddress = (e) => {
        if (this.state.memberBillingAddress.postCode != null && this.state.memberBillingAddress.postCode.length >= 6) {
            this.addressService.getAddresses(localStorage.getItem("merchantBranchId"), this.state.memberBillingAddress.postCode).then(response => {
                this.setState({filteredBillingAddresses: response});
                if (response.length > 0) {
                    e.which = 99;
                    this.dropDownBillingAddress.onInputKeyDown(e);
                } else {
                    return toast.info("No Available Address");
                }

            });

        } else {
            this.setState({filteredBillingAddresses: []});
        }

    }

    getAddressDetail = (displayString) => {
        return this.state.filteredAddresses.filter(function (el) {
            return el.displayString == displayString;
        });
    }

    getBillingAddressDetail = (displayString) => {
        return this.state.filteredBillingAddresses.filter(function (el) {
            return el.displayString == displayString;
        });
    }

    createDojoTokenAgain = () => {
        if (this.state.order.provider === "DOJO") {
            if (Number.isInteger(this.state.paymentOption) || (this.state.paymentOption == "CARD" || this.state.paymentOption == "WALLET")) {
                this.removeCardIframes();
                this.createAccessTokenForDojo(this.state.crossReference, this.state.paymentOption)

            }
        }

    }

    getAddressDetailById = (id) => {
        let data = this.state.addressList.filter(function (el) {
            return el.id == id;
        });
        if (!GeneralUtils.isNullOrEmpty(data)) {
            return this.setAddressToState(data[0]);
        } else {
            return Object.assign({}, defaultMemberUserAddress);
        }

    }

    getBillingAddressDetailById = (id) => {
        let data = this.state.billingAddressList.filter(function (el) {
            return el.id == id;
        });
        if (!GeneralUtils.isNullOrEmpty(data)) {
            return this.setAddressToState(data[0]);
        } else {
            return Object.assign({}, defaultMemberUserAddress);
        }

    }

    getAddressDetailFromFilteredCombo(data) {
        let address = {};
        address["postCode"] = data.postCode;
        address["description"] = data.postCode;
        address["addressLine1"] = data.addressLines[0];
        address["addressLine2"] = data.addressLines[1];
        address["addressLine3"] = data.addressLines[2];
        address["addressLine4"] = data.addressLines[3];
        address["city"] = data.postTown;
        return address;
    }

    setOrderTypeAndAddresFromMemberPage(){
        if(!GeneralUtils.__isValueEmpty(localStorage.getItem("orderType")) && localStorage.getItem("orderType")!="undefined"){
            let orderType = localStorage.getItem("orderType");
            let order = this.state.order;
            order["orderType"] = orderType;
            this.getBasketInfo(orderType);
            this.setState({
                order: order
            });

            if (orderType == "Collection" ||  orderType == "Table Order") {
                this.setState({
                    sameAsDeliveryAddress: false
                });
            }
            else if(orderType=="Delivery"){

                this.setState({
                    sameAsDeliveryAddress: true
                });

                let memberUserAddress = Object.assign({}, defaultMemberUserAddress);
                if(!GeneralUtils.isNullOrEmpty(localStorage.getItem("postCode")) && localStorage.getItem("postCode")!="undefined"){
                    memberUserAddress["postCode"] = localStorage.getItem("postCode");
                    this.setState({
                        showDeliveryAddressDetails: true,addressOption:"Other"
                    });
                }
                if(!GeneralUtils.isNullOrEmpty(localStorage.getItem("description")) && localStorage.getItem("description")!="undefined"){
                    memberUserAddress["description"] = localStorage.getItem("description");
                }

                if(!GeneralUtils.isNullOrEmpty(localStorage.getItem("addressLine2")) && localStorage.getItem("addressLine2")!="undefined"){
                    memberUserAddress["addressLine2"] = localStorage.getItem("addressLine2");
                }
                if(!GeneralUtils.isNullOrEmpty(localStorage.getItem("addressLine3")) && localStorage.getItem("addressLine3")!="undefined"){
                    memberUserAddress["addressLine3"] = localStorage.getItem("addressLine3");
                }
                if(!GeneralUtils.isNullOrEmpty(localStorage.getItem("addressLine4")) && localStorage.getItem("addressLine4")!="undefined"){
                    memberUserAddress["addressLine4"] = localStorage.getItem("addressLine4");
                }
                if(!GeneralUtils.isNullOrEmpty(localStorage.getItem("city")) && localStorage.getItem("city")!="undefined"){
                    memberUserAddress["city"] = localStorage.getItem("city");
                }

                if(!GeneralUtils.isNullOrEmpty(localStorage.getItem("addressLine1")) && localStorage.getItem("addressLine1")!="undefined"){
                    memberUserAddress["addressLine1"] = localStorage.getItem("addressLine1");
                    this.setState({memberUserAddress: memberUserAddress});
                }


            }
        }
    }


}

