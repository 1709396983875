import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class DepartmentService extends Service {
    constructor() {
        super();
        this.baseURL = 'product/department';
    }

    async hasConnectedAnyMixAndMatch(id) {
        return fetch(SERVER_URL + this.baseURL + "/" + id + "/hasConnectedAnyMixAndMatch", {
            headers: { 'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async deleteAndTransfer(id,transferDepartmentId) {
        return fetch(SERVER_URL + this.baseURL + "/" + id + "/transferDepartmentId/"+transferDepartmentId, {
            crossDomain: true,
            method: 'delete',
            headers: { 'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }




}