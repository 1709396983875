import React from 'react';
import {Button, Col, Input, Label, Row} from 'reactstrap';
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import {AvGroup, AvForm} from "availity-reactstrap-validation";
import AvField from "../../shared/component/form/AvField";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import CustomerService from "../../shared/service/CustomerService";
import {PERSON_TITLE} from "../../shared/utils/Constants";
import {PhoneField} from "../../shared/component/form/PhoneField";
import DateInput from "../../shared/component/form/DateInput";
import {InputNumber} from "primereact/inputnumber";

const defaultMemberUserAddress = {
    id: null,
    description: '',
    postCode: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    defaultAddress: true
};

const defaultMemberUser = {
    id: null,
    email: '',
    firstName: '',
    lastName: '',
    birthDay: null,
    password: '',
    status: 'ACTIVE',
    mobilePhone: null,
    phone2:null,
    title: 'MR',
    balance:null,
    overdraft:null


};


export default class CustomerSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            memberUserAddress: defaultMemberUserAddress,
            memberUserAddressTemp: defaultMemberUserAddress,
            memberUser: defaultMemberUser,
            addressList: []
        }
        this.customerService = new CustomerService();

    }

    componentDidMount() {
        let id = window.location.href.split('?')[1];


        if (!GeneralUtils.__isValueEmpty(id)) {
            this.customerService.findById(id).then(response => {
                    this.setState({
                        memberUser: response,
                        memberUserTemp: Object.assign({}, response)
                    });
                }
            );
            this.customerService.findAddressesOfUser(id).then(response => this.setState({addressList: response}));
        }

    }


    __handleChangeList = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        if (!GeneralUtils.isNullOrEmpty(value)) {
            this.customerService.findByAddressId(value).then(response => this.setState({
                memberUserAddress: response,
                memberUserAddressTemp: Object.assign({}, response),
                memberUserAddressId: value,
                memberUserAddressSaveButtonRender: false
            }));


        } else {
            this.setState({
                memberUserAddressSaveButtonRender: false,
                memberUserAddress: defaultMemberUserAddress,
                memberUserAddressTemp: defaultMemberUserAddress,
                memberUserAddressId: null,

            });
        }
    }


    render() {
        return (
            <AvForm>
                {this.renderProfileInfo()}
                {this.renderMemberUserAddress()}


            </AvForm>
        );
    }

    renderProfileInfo() {
        return (<Fieldset legend={"Profile"}>


            <Row>
                <Col md="4">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.emailAddress">Email
                            </Translate>
                        </Label>
                        <AvField
                            type="email"
                            className="form-control"
                            name="email"
                            disabled={true}
                            validate={{
                                minLength: {
                                    "value": 5, "errorMessage": "Not Valid Email"
                                }, maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 255 characters in this field."
                                }
                            }}
                            value={this.state.memberUser.email}
                            onChange={this.__handleChange}/>
                    </AvGroup>
                </Col>
                <Col md="4">
                    <AvGroup>
                        <Label for="tradingName">
                            <Translate contentKey="merchantBranch.tradingName">Title</Translate>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="title"
                            disabled={!this.state.memberUserSaveButtonRender}
                            value={this.state.memberUser.title}
                            onChange={this.__handleChange}>
                            {PERSON_TITLE.map(element => (<option value={element.value} key={element.value}>
                                {element.name}
                            </option>))}
                        </AvField>
                    </AvGroup>
                </Col>
                <Col md="4">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">First Name
                            </Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="firstName"
                            disabled={!this.state.memberUserSaveButtonRender}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 255 characters in this field."
                                }
                            }}
                            value={this.state.memberUser.firstName}
                            onChange={this.__handleChange}/>
                    </AvGroup>
                </Col>
                <Col md="4">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Last Name
                            </Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="lastName"
                            disabled={!this.state.memberUserSaveButtonRender}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 255 characters in this field."
                                }
                            }}
                            value={this.state.memberUser.lastName}
                            onChange={this.__handleChange}/>
                    </AvGroup>
                </Col>
                <Col md="4">
                    <AvGroup>
                        <Label for="mobilePhone">
                            <Translate contentKey="contactManagement.mobilePhone">Mobile
                                Phone</Translate>
                        </Label>
                        <PhoneField kind={'mobile'}
                                    disabled={!this.state.memberUserSaveButtonRender}
                                    name="mobilePhone" errorMessage="Not Valid Phone Number"
                                    value={this.state.memberUser.mobilePhone}
                                    onChange={this.__handleChange}
                        />
                    </AvGroup>
                </Col>
                <Col md="4">
                    <AvGroup>
                        <Label for="mobilePhone">
                            <Translate contentKey="contactManagement.mobilePhone">
                                Phone2</Translate>
                        </Label>
                        <PhoneField kind={'mobile'}
                                    disabled={!this.state.memberUserSaveButtonRender}
                                    name="phone2" errorMessage="Not Valid Phone Number"
                                    value={this.state.memberUser.phone2}
                                    onChange={this.__handleChange}
                        />
                    </AvGroup>
                </Col>
                <Col md="4">

                    <AvGroup>
                        <Label for="tradingName">
                            <Translate
                                contentKey="merchantBranch.tradingName">Date of birth</Translate>
                        </Label>

                        <DateInput
                            name="birthDay"
                            format={"DD/MM/YYYY"}
                            className="form-control"
                            disableClock={true}
                            onChange={this.__handleChange}
                            disabled={!this.state.memberUserSaveButtonRender}
                            value={this.state.memberUser.birthDay}/>


                    </AvGroup>
                </Col>

                <Col md="4">
                    <AvGroup>
                        <Label for="tradingName">
                            <Translate contentKey="producttradingName">Balance</Translate>
                        </Label>

                        <InputNumber
                            name="balance"
                            disabled={!this.state.memberUserSaveButtonRender}
                            value={this.state.memberUser.balance}
                            onChange={this.__handleChange}
                            className="table-responsive"
                        />
                    </AvGroup>
                </Col>
                <Col md="4">
                    <AvGroup>
                        <Label for="tradingName">
                            <Translate contentKey="producttradingName">Overdraft</Translate>
                        </Label>

                        <InputNumber
                            name="overdraft"
                            disabled={!this.state.memberUserSaveButtonRender}
                            value={this.state.memberUser.overdraft}
                            onChange={this.__handleChange}
                            className="table-responsive"
                        />
                    </AvGroup>
                </Col>
            </Row>





        </Fieldset>)
    }

    renderMemberUserAddress() {
        return (<Row>

            <Col md={12}>

                <Fieldset legend={"Addresses"}>
                    <Row>
                        <Col md="4">
                            <AvField
                                type="select"
                                disabled={false}
                                className="form-control"
                                name="tax"
                                value={this.state.memberUserAddressId}
                                onChange={this.__handleChangeList}>

                                <option value='' key=''/>
                                {this.state.addressList.map(element => (
                                    <option value={element.id} key={element.id}>
                                        {element.description}
                                    </option>))}
                            </AvField>


                        </Col>
                    </Row>

                </Fieldset>

                <Fieldset legend={"Address Details"}>


                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Post Code
                                    </Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>

                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="postCode"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    value={this.state.memberUserAddress.postCode}
                                    onChange={this.__handleChange}>
                                </AvField>


                            </AvGroup>

                        </Col>


                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">City
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="city"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberUserAddress.city}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>

                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Description
                                    </Translate>
                                </Label>

                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    value={this.state.memberUserAddress.description}
                                    onChange={this.__handleChange}>
                                </AvField>

                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 1
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine1"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 50,
                                            errorMessage: "You can enter up to 50 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberUserAddress.addressLine1}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 2
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine2"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberUserAddress.addressLine2}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 3
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine3"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberUserAddress.addressLine3}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 4
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine4"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberUserAddress.addressLine4}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                    </Row>


                    {this.state.memberUserAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                onClick={() => {
                                    let obj1 = this.state.memberUserAddressTemp;
                                    this.setState({
                                        memberUserAddress: Object.assign({}, obj1),
                                        memberUserAddressSaveButtonRender: false
                                    });
                                }}>
                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>
                    }


                </Fieldset>

            </Col>
        </Row>);
    }


    _

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let memberUserAddress = this.state.memberUserAddress;
        memberUserAddress[e.target.name] = value;
        this.setState({memberUserAddress: memberUserAddress});
    }

}


