import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import DateInput from "../../shared/component/form/DateInput";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {BASKET_STATUS, BASKET_TYPE, MONTH, ORDER_STATUS, ORDER_TYPE, PAGE_MODE} from "../../shared/utils/Constants";
import {InputNumber} from "primereact/inputnumber";
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import OrderItemsColumn from "../../shared/component/dataTable/columns/OrderItemsColumn.json";
import OrderService from "../../shared/service/OrderService";
import {TabPanel, TabView} from "primereact/tabview";
import MerchantUserService from "../../shared/service/MerchantUserService";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";

const defaultOrder = {
    id: null,
    orderNo: null,
    orderDate: null,
    basketStatus: null,
    syncStatus: null,
    orderStatus: null,
    acceptedDate: null,
    preparingDate: null,
    readyDate: null,
    deliveredDate: null,
    totalPrice: null,
    orderType: null,
    basketDetailList: []
};
export default class OrderHistoryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: defaultOrder,
            pageMode: null
        }
        this.orderService = new OrderService();
        this.merchantUserService = new MerchantUserService();
    }

    componentDidMount() {
        this.merchantUserService.getDrivers().then(response => this.setState({
            driverList: response
        }));
    }


    render() {
        return (

            <div class="p-col-12">
                <ModalConfirm header='Warning' ok='OK' onOkClick={this.refund}
                              onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                              show={this.state.displayShowStatusConfirmDialog}
                              message={this.state.noticeResponse}/>
                <TabView>

                    <TabPanel header="Order Detail" >

                        <Row>

                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Order No</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="orderNo"
                                        disabled={true}
                                        value={this.state.order.orderNo}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>


                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Order Type</Translate>
                                    </Label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="orderType"
                                        disabled={true}
                                        value={this.state.order.orderType}
                                        onChange={this.__handleChange}>
                                        {ORDER_TYPE.map(element => (<option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>))}
                                    </AvField>
                                </AvGroup>
                            </Col>
                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Basket Type</Translate>
                                    </Label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="basketType"
                                        disabled={true}
                                        value={this.state.order.basketType}
                                        onChange={this.__handleChange}>
                                        {BASKET_TYPE.map(element => (<option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>))}
                                    </AvField>
                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Status</Translate>
                                    </Label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="basketStatus"
                                        disabled={true}
                                        value={this.state.order.basketStatus}
                                        onChange={this.__handleChange}>
                                        {BASKET_STATUS.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>
                                </AvGroup>
                            </Col>
                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Order Status</Translate>
                                    </Label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="orderStatus"
                                        disabled={true}
                                        value={this.state.order.orderStatus}
                                        onChange={this.__handleChange}>
                                        {ORDER_STATUS.map(element => (<option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>))}
                                    </AvField>
                                </AvGroup>
                            </Col>
                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Sync Status</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="syncStatus"
                                        disabled={true}
                                        value={this.state.order.syncStatus}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>
                        </Row>

                        {(this.state.order.orderType == "DELIVERY" ||this.state.order.orderType == "COLLECTION" ) &&
                            <Row>

                                <Col md="4">
                                    <AvGroup>
                                        {this.state.order.orderType == "DELIVERY" &&

                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Deliver by
                                                </Translate>
                                            </Label>
                                        }

                                        {this.state.order.orderType == "COLLECTION" &&

                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Ready by
                                                </Translate>
                                            </Label>
                                        }
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="deliveryDateString"
                                            disabled={true}
                                            value={this.state.order.deliveryDateString}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                </Col>

                                <Col md="4">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="merchantBranch.tradingName">Order
                                                Date</Translate>
                                        </Label>

                                        <DateInput
                                            name="orderDate"
                                            onChange={this.__handleChange}
                                            disabled={true}
                                            value={this.state.order.orderDate}/>

                                    </AvGroup>
                                </Col>
                            </Row>
                        }

                        <Row>









                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Total Price</Translate>
                                    </Label>

                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="totalPriceString"
                                        disabled={true}
                                        value={this.state.order.totalPriceString}
                                        onChange={this.__handleChange}/>



                                </AvGroup>
                            </Col>

                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Service
                                            Charge</Translate>
                                    </Label>

                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="calculatedServiceChargeString"
                                        disabled={true}
                                        value={this.state.order.calculatedServiceChargeString}
                                        onChange={this.__handleChange}/>


                                </AvGroup>
                            </Col>

                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Discount</Translate>
                                    </Label>

                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="manualDiscountString"
                                        disabled={true}
                                        value={this.state.order.manualDiscountString}
                                        onChange={this.__handleChange}/>


                                </AvGroup>
                            </Col>

                            {this.state.order.orderType == "DELIVERY" &&
                                <Col md="4">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="merchantBranch.tradingName">Delivery
                                                Fee</Translate>
                                        </Label>

                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="calculatedDeliveryFeeString"
                                            disabled={true}
                                            value={this.state.order.calculatedDeliveryFeeString}
                                            onChange={this.__handleChange}/>


                                    </AvGroup>
                                </Col>
                            }


                        </Row>



                        <Row>
                            {!GeneralUtils.isNullOrEmpty(this.state.order.customerFullName) &&
                                <Col md="4">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="merchantBranch.tradingName">Customer
                                            </Translate>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="customerFullName"
                                            disabled={true}
                                            value={this.state.order.customerFullName}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                </Col>
                            }
                            <Col md="8">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Deliver Address</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="deliveryAddress"
                                        disabled={true}
                                        value={this.state.order.deliveryAddress}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>
                        </Row>



                        <Row>


                            <Col md={"4"}>

                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Order Notes
                                        </Translate>
                                    </Label>
                                    <AvField
                                        type="textarea"
                                        className="form-control textAreaPosmarketSmall"
                                        name="orderNotes"
                                        disabled={true}
                                        value={this.state.order.orderNotes}
                                        onChange={this.__handleChange}>
                                    </AvField>
                                </AvGroup>


                            </Col>

                            <Col md={"4"}>

                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Allergies
                                        </Translate>
                                    </Label>
                                    <AvField
                                        type="textarea"
                                        className="form-control textAreaPosmarketSmall"
                                        name="allergies"
                                        disabled={true}
                                        value={this.state.order.allergies}
                                        onChange={this.__handleChange}>
                                    </AvField>
                                </AvGroup>


                            </Col>
                        </Row>

                        <Row>
                            { ((this.state.order.orderStatus == "ORDER_READY" && this.state.order.orderType == "DELIVERY") || (!GeneralUtils.isNullOrEmpty(this.state.order.driverId))) &&
                                <Col md="4">
                                    <AvGroup>
                                        <Label for="mobilePhone">
                                            <Translate
                                                contentKey="contactManagement.mobilePhone">Driver</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>

                                        <AvField
                                            type="select"
                                            disabled={GeneralUtils.isNullOrEmpty(this.state.order.driverId) ? false : true}
                                            className="form-control"
                                            name="driverId"
                                            value={this.state.order.driverId}
                                            onChange={this.__handleChange}>

                                            <option value='' key=''/>
                                            {this.state.driverList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.fullName}
                                                </option>))}
                                        </AvField>

                                    </AvGroup>
                                </Col>
                            }
                        </Row>
                    </TabPanel>
                    <TabPanel header="Dates & Fees">
                        <Row>

                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Accepted Date</Translate>
                                    </Label>

                                    <DateInput
                                        name="acceptedDate"
                                        onChange={this.__handleChange}
                                        disabled={true}
                                        value={this.state.order.acceptedDate}/>

                                </AvGroup>
                            </Col>
                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Preparing Date</Translate>
                                    </Label>

                                    <DateInput
                                        name="preparingDate"
                                        onChange={this.__handleChange}
                                        disabled={true}
                                        value={this.state.order.preparingDate}/>

                                </AvGroup>
                            </Col>


                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Ready Date</Translate>
                                    </Label>

                                    <DateInput
                                        name="readyDate"
                                        onChange={this.__handleChange}
                                        disabled={true}
                                        value={this.state.order.readyDate}/>

                                </AvGroup>
                            </Col>
                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Delivered Date</Translate>
                                    </Label>

                                    <DateInput
                                        name="deliveredDate"
                                        onChange={this.__handleChange}
                                        disabled={true}
                                        value={this.state.order.deliveredDate}/>

                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Items Total
                                            Price</Translate>
                                    </Label>

                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="subTotalPriceString"
                                        disabled={true}
                                        value={this.state.order.subTotalPriceString}
                                        onChange={this.__handleChange}/>


                                </AvGroup>
                            </Col>

                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="merchantBranch.tradingName">Service
                                            Charge</Translate>
                                    </Label>

                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="calculatedServiceChargeString"
                                        disabled={true}
                                        value={this.state.order.calculatedServiceChargeString}
                                        onChange={this.__handleChange}/>


                                </AvGroup>
                            </Col>
                            {this.state.order.orderType == "DELIVERY" &&
                                <Col md="4">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="merchantBranch.tradingName">Delivery
                                                Fee</Translate>
                                        </Label>

                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="calculatedDeliveryFeeString"
                                            disabled={true}
                                            value={this.state.order.calculatedDeliveryFeeString}
                                            onChange={this.__handleChange}/>


                                    </AvGroup>
                                </Col>
                            }
                        </Row>
                    </TabPanel>
                </TabView>

                <Fieldset legend={"Order"}>





                    <Fieldset legend={"Items"}>
                        <DataTableWithButton data={this.state.order.basketDetailList}
                                             ref={(elem) => this.refDataTable = elem}
                                             selectionMode='single'
                                             paginator={false}
                                             fields={OrderItemsColumn.fields}> </DataTableWithButton>


                    </Fieldset>

                    <Row>
                        <Col>
                            <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                    onClick={() => {
                                        this.printReceipt();
                                    }}>
                                <Translate contentKey="entity.action.add">Print Receipt</Translate>
                            </Button>
                        </Col>

                        {GeneralUtils.isClerk() && this.state.order.basketType == "sale" && this.state.order.remote == false && this.state.order.basketStatus == "PAID" && this.state.order.orderStatus == "ORDER_CLOSED" &&
                            <Col>
                                <Button color="outline-danger" style={{marginBottom: '5px', width: '100%'}}
                                        onClick={() => {
                                            this.showChangeStatusConfirmDialog(true)
                                        }}>
                                    <Translate contentKey="entity.action.add">Refund</Translate>
                                </Button>
                            </Col>}
                    </Row>


                    <Row>



                        <Col md="4">
                            <Button color="outline-primary" onClick={() => {

                                this.props.closeDisplayDialog();

                            }}>
                                <Translate contentKey="entity.action.cancel">Close</Translate>
                            </Button>

                        </Col>

                    </Row>

                    <Row>

                    </Row>


                </Fieldset>
            </div>
        );
    }


    showChangeStatusConfirmDialog = (value) => {
        this.setState({
            displayShowStatusConfirmDialog: true,
            noticeResponse: "Are you sure to refund?"
        });
    }
    setData = (data) => {
        this.setState({order: data});
    }

    clearData = () => {
        let obj = Object.assign({}, defaultOrder);
        this.setState({order: obj});
    }

    refund = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.refund(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.closeDisplayDialog();
                }
                this.setState({loading: false,displayShowStatusConfirmDialog:false});
            });
        }

    }

    printReceipt = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.printReceipt(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.closeDisplayDialog();
                }
                this.setState({loading: false});
            });
        }

    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let order = this.state.order;
        order[e.target.name] = value;
        this.setState({order: order});
    }

}

OrderHistoryDetail.propTypes = {
    order: PropTypes.object
};
